import axios from 'axios';
import { stringify } from 'query-string';
import { format, addDays, endOfDay, startOfDay } from 'date-fns';
import uniq from 'lodash/uniq';
import ApolloClient from 'apollo-boost';

import { LOGOUT_SUCCESS } from '../constants/actions';
import {
  LOGIN_URL,
  LOGOUT_URL,
  SAVE_FILTERS_URL,
  RESET_FILTERS_TEMPLATE_URL,
  DELETE_DEV_INCIDENT_BY_ID,
  GET_BY_RULE_GROUP_REPORT_SCHEDULE_URL,
  GET_BY_RULE_REPORT_SCHEDULE_URL,
  GET_BY_PERIOD_REPORT_SCHEDULE_URL,
  GET_BY_MONTH_REPORT_SCHEDULE_URL,
  GET_FLAGGED_REPORT_SCHEDULE_URL,
  GET_KRI_REPORT_SCHEDULE_URL,
  GET_GRAPH_REPORT_SCHEDULE_URL,
  ALGO_FINE_TUNING_SCHEDULE_URL,
  RESET_FINE_TUNING_SCHEDULE_URL,
  GET_FINE_TUNINGS_SCHEDULE_URL,
  SET_FINE_TUNINGS_SCHEDULE_URL,
  UPDATE_ALGOS_ACTIVATED_SCHEDULE_URL,
  GET_BY_RULE_GROUP_REPORT_ANALYTIC_URL,
  GET_BY_RULE_REPORT_ANALYTIC_URL,
  GET_BY_PERIOD_REPORT_ANALYTIC_URL,
  GET_BY_MONTH_REPORT_ANALYTIC_URL,
  GET_FLAGGED_REPORT_ANALYTIC_URL,
  GET_KRI_REPORT_ANALYTIC_URL,
  GET_GRAPH_REPORT_ANALYTIC_URL,
  ALGO_FINE_TUNING_ANALYTIC_URL,
  RESET_FINE_TUNING_ANALYTIC_URL,
  GET_FINE_TUNINGS_ANALYTIC_URL,
  SET_FINE_TUNINGS_ANALYTIC_URL,
  UPDATE_FINE_TUNINGS_OPERATORS_URL,
  UPDATE_ALGOS_ACTIVATED_ANALYTIC_URL,
  RUN_ALGO_ANALYTIC_URL,
  RUN_ALL_SELECTED_ALGOS_ANALYTIC_URL,
  STOP_RUNNING_ALGO_ANALYTIC_URL,
  GET_NEWS_URL,
  DELETE_DEV_DEALS_ORDERS_INCIDENTS,
  DELETE_DEV_NEWS,
  DELETE_DEV_MKT,
  DELETE_DEV_MKT_DAYS,
  DELETE_DEV_INCIDENTS_BY_RULEGROUP,
  RELOAD_DEV_MKT_MINUTES,
  RELOAD_DEV_MKT_DAYS,
  RELOAD_DEV_REFRATES_SECURITIES,
  RELOAD_DEV_REFISSUES,
  RELOAD_DEV_CASH_REFISSUES,
  LOAD_DEV_REFISSUES_BY_REFISSUES_FROM_MOEX,
  LOAD_DEV_DEALS_IMPORT,
  LOAD_DEV_ORDERS_IMPORT,
  UPDATE_RELATED_DEALS_ORDERS,
  GET_CASE_USERS_URL,
  CREATE_CASE_URL,
  UPDATE_CASE_URL,
  DELETE_CASE_URL,
  CREATE_CASE_ACTIVITY_URL,
  UPDATE_CASE_ACTIVITY_URL,
  DELETE_CASE_ACTIVITY_URL,
  ADD_INCIDENTS_TO_CASE_URL,
  REMOVE_INCIDENTS_FROM_CASE_URL,
  GET_CASE_REPORT_URL,
  GET_CASE_INCIDENT_REPORT_URL,
  GET_INCIDENT_REPORT_URL,
  GET_CASE_FILE_URL,
  GET_CASE_DOC_REPORT_URL,
  FIX_ISSUES_URL,
  GET_ASSETS,
  GET_ASSET_CLASSES,
  GET_BUSINESSLINES,
  UPDATE_REF_COUNTERPARTY_URL,
  UPDATE_REF_INSIDERS_URL,
  UPDATE_REF_ISSUES_URL,
  UPDATE_REF_STAFF_URL,
  GET_TASKS_URL,
  GET_BUSINESS_SETTINGS_URL,
  GET_ADMIN_USERS_URL,
  CREATE_USER_URL,
  EDIT_FULL_USER_URL,
  DISABLE_USER_URL,
  ENABLE_USER_URL,
  RESET_USERS_DEFAULT_SETTINGS_URL,
  EDIT_USER_REPORT_URL,
  EDIT_USER_REPORT_FILTER_URL,
  EDIT_USER_REPORT_CONNECTION_URL,
  EDIT_USER_REPORT_AFFILIATED_URL,
  LOAD_DEV_REF_ISSUES_BY_TICKERS,
  LOAD_DEV_MKT_BY_TICKERS,
  LOAD_DEV_MKT_DAYS_BY_TICKERS,
  SANDBOX,
  RUN_CUSTOM_ALGORITHM,
  GET_ADMIN_PROPERTIES,
  GET_MAINTENANCE_URL,
  STOP_MAINTENANCE_URL,
  START_MAINTENANCE_URL,
  UPDATE_INCIDENT_MANAGEMENT,
  FIND_INCIDENT_MANAGMENT_HISTORY,
  GET_TASK_HISTORY_BY_ID,
  GET_TAFS_ROLES_URL,
  GET_ROLE_PERMISSIONS_URL,
  GET_REF_HISTORY,
  CREATE_ALGO_TEMPLATE_URL,
  DELETE_ALGO_TEMPLATE_URL,
  FORCE_UPDATE_ALGO_RUN,
  GET_USERS_CSV_URL,
  WATCH_LIST_URL,
  CREATE_WATCH_LIST,
  UPDATE_WATCH_LIST,
  ADD_TO_WATCH_LIST,
  GET_WATCHLIST_CHART_DATA_URL,
  UPDATE_MODULES_LOGGING,
  GET_CLIENT_ACTIVITY_URL,
  GET_CLIENT_ACTIVITY_WITH_TICKER_URL,
  GET_CLIENT_CONNECTION_STATS,
  GET_CLIENT_CONNECTION_STATS_PER_DATES,
  GET_CLIENT_CONNECTION_STATS_ENUM,
  DOWNLOAD_CONNECTIONS_STATS_REPORT,
  GET_AFIILIATION_CHAINS,
  DOWNLOAD_AFFILIATION_REPORT,
  GENERATE_XLS,
  GET_APP_VERSION,
  RECALCULATE_SIGNALS,
  ALGO_FINE_TUNING_SIGNAL_URL,
  RESET_FINE_TUNING_SIGNAL_URL,
  RUN_ALGO_SIGNAL_URL,
  CREATE_OVERLAP_REPORT,
  DOWNLOAD_OVERLAP_REPORT,
  GET_OVERLAP_INCIDENTS,
  RECOVER_ALGO_TEMPLATE_URL,
  GET_RULES_EDIT_USERS,
  RUN_INCIDENT_RANKING,
  GET_OPTIMIZATION_TASK_BY_RULECODE_URL,
  RUN_FINE_TUNING_OPTIMIZATION_URL,
  GET_FINE_TUNING_VALUE_FROM_PERCENTILE_URL,
  GET_FINE_TUNING_PERCENTILE_FROM_VALUE_URL,
  GET_ML_CHART_UNFLAGGED_DATA_URL,
  GET_ML_CHART_FLAGGED_DATA_URL,
  GET_ML_CHART_CALC_DATA_URL,
  POST_ML_SETTINGS_FILE_URL,
  GET_ML_PIVOT,
  ALGO_ML_FINE_TUNING_SCHEDULE_URL,
  ALGO_ML_FINE_TUNING_ANALYTIC_URL,
  UPDATE_REF_INDEXES_URL,
  UPDATE_REF_FIXING_INTEREST_URL,
  UPDATE_REF_MOEX_FX_URL,
  GET_UNCALCULATED_INCIDENTS_REPORT,
  SAVE_UNCALCULATED_INCIDENTS_REPORT,
  DOWNLOAD_UNCALCULATED_INCIDENTS_REPORT,
  GET_AUDIT_DEALS_REPORT,
  GET_AUDIT_ORDERS_REPORT,
  UPDATE_AFFILIATION_FIELD_URL,
  RUN_AFFILIATED_STATISTICS_URL,
  DOWNLOAD_AUDIT_DEALS_REPORT,
  DOWNLOAD_AUDIT_ORDERS_REPORT,
  UPDATE_OVERLAP_REPORT_STATUS_URL,
  UPDATE_OVERLAP_REPORT_SUB_STATUS_URL,
  REGENERATE_INCIDENTS_BY_DATE,
  UPLOAD_REF_DOCUMENT,
  DELETE_REF_DOCUMENT,
  DOWNLOAD_REF_DOCUMENT,
  CREATE_CASE_REGULATOR_REPORT,
  DOWNLOAD_AUDIT_DEALS_ORDERS_REPORT,
  GET_AUDIT_INCIDENTS_REPORT,
  GET_AUDIT_SIGNALS_REPORT,
  RUN_SCAN_ALGO,
  STOP_SCAN_ALGO,
  UPDATE_CLIENT_CONCLUSION,
  DELETE_CLIENT_CONCLUSION,
  ADD_USER_FILE_URL,
  ADD_INPUT_FILE_URL,
  DELETE_USER_FILE_URL,
  DELETE_INPUT_FILE_URL,
  VERIFY_CUSTOM_ALGORITHM,
  STOP_VERIFY_CUSTOM_ALGORITHM,
  GET_TASK_BY_ID,
  DRAFT_CUSTOM_RULE,
} from '../constants/api';
import { SCHEDULE, IRRESPECTIVE } from '../constants/appModes';
import { workspaceObj } from '@tafs/constants/workspaces';
import { apiHost } from '../settings';
import store from '../store';
import {
  getAppPermissionsQuery,
  getRuleFineTuningAnalyticQuery,
  getDealQuery,
  getOrderQuery,
  getOrderCalculatedQuery,
  getIssueByTickerVenueQuery,
  getAlgorithmsActivatedQuery,
  getScheduleAlgorithmsActivatedQuery,
  getUserDisplaySettingsQuery,
  getUserFiltersQuery,
  getCaseQuery,
  getCaseActivitiesQuery,
  getIncidentQuery,
  getNewsQuery,
  getIncidentReportRowQuery,
  getChartDataQuery,
  getDashboardChartInitialParametersQuery,
  getGlobalSummaryQuery,
  getFiltersTemplateQuery,
  getRuleFineTuningScheduleQuery,
  getRuleParamsValidationQuery,
  areDealsInIncidentQuery,
  areOrdersInIncidentQuery,
  getRuleDetailsQuery,
  getRuleCodesQuery,
  getWatchListByIdQuery,
  getWatchListsByClientQuery,
  getClientsByWatchListQuery,
  getCounterpartyQuery,
  getInsiderQuery,
  getCounterpartyAffiliatedReport,
  getOrderIdQuery,
  getDealIdQuery,
  getIncidentSerialIdQuery,
  getGlobalTickersAnalyticQuery,
  getGlobalTickersScheduleQuery,
  getIssuesByTickerQuery,
  getCounterpartiesByIdQuery,
  getRuleGroupParamsValidationQuery,
  getSignalInfoForAnalyticIBQuery,
  getSignalInfoForAnalyticComplianceQuery,
  getMMSignalInfoForAnalyticIBQuery,
  getMMSignalInfoForAnalyticComplianceQuery,
  getOverlapClientsChartDataQuery,
  getOverlapDealsChartDataQuery,
  getOverlapOrdersChartDataQuery,
  getOverlapIncidentsQuery,
  getUnprocessedIncidentsChartDataQuery,
  getAvailableRuleGroupsQuery,
  getIncidentJudgementQuery,
  getRankingIncidentsQuery,
  getMarketInputsByRuleQuery,
  getChartDataWithoutClientFilterQuery,
  getRuleGroupFineTuningsSignalQuery,
  getCounterpartyUuidQuery,
  getReferenceFilesQuery,
  getStaffQuery,
  getClientStatisticsQuery,
  getIncidentsByClientQuery,
  getDealsClientAndTickerQuery,
  getOrdersClientAndTickerQuery,
  getClientConclusionQuery,
  getCustomRuleDraftQuery,
  getDraftHistoryQuery,
} from '@tafs/constants/queries';
import { OPEN as statusOpen } from '@tafs/constants/cases/statuses';
import { parsePermissions } from '@tafs/constants/permissions';
import { getGlobalFilterUrl } from '@tafs/constants/globalFilters';
import { INCIDENTS_DRP } from '@tafs/constants/overlaps/types';
import { auditReportTypes } from '@tafs/constants/auditReport';
import { effectivenessRuleGroup } from '@tafs/constants/reports/ruleGroups';

const getResult = (response) => response.data;
const { GPB_MODE } = process.env;

const axi = axios.create({
  baseURL: apiHost,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

// Add a response interceptor
// when response status is unautorized we perform logout
axi.interceptors.response.use(undefined, (data) => {
  if (data.response.status === 401) {
    store.dispatch({ type: LOGOUT_SUCCESS });
  } else {
    const error =
      data.response.data.message ||
      data.response.data.error ||
      data.response.data ||
      data.message ||
      data.response.statusText;
    throw new Error(error);
  }
  const error =
    data.response.data.message || data.message || data.response.statusText;
  throw new Error(error);
});

export const { get: apiGet } = axi;
export const { post: apiPost } = axi;

export const graphql = new ApolloClient({
  uri: apiHost + '/graphql',
});

export function login(username, password) {
  let responseStatus;
  return axi
    .post(
      LOGIN_URL,
      stringify({
        username,
        password,
      }),
      {
        validateStatus: (status) => {
          responseStatus = status;
          return status < 400;
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then(getResult)
    .catch(() => Promise.reject(responseStatus));
}

export function logout() {
  return new Promise((resolve, reject) =>
    axi
      .post(LOGOUT_URL)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const getAppPermissions = () => {
  return graphql.query(getAppPermissionsQuery()).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    const permissions = res.data.AppPermissionEntityList.content;
    return {
      parsedPermissions: parsePermissions(permissions.map((row) => row.value)),
      permissionIdObj: permissions.reduce((acc, cur) => {
        acc[cur.value] = cur.id;
        return acc;
      }, {}),
    };
  });
};

export function getAlgorithmsActivated(user, mode, workspace) {
  return new Promise((resolve, reject) => {
    const query =
      mode === SCHEDULE
        ? getScheduleAlgorithmsActivatedQuery
        : getAlgorithmsActivatedQuery;

    return graphql
      .query(query(workspace, user))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }

        const res =
          mode === SCHEDULE
            ? response.data.FineTuningScheduleEntityList.content.map(
                (algo) => ({
                  isArchived: algo.rule.archived,
                  isActivated: algo.isActive,
                  ruleId: algo.rule.ruleCode,
                  ruleGroup: algo.rule.ruleGroup,
                  parentRefRule: algo.rule.parentRefRule,
                  ruleName: algo.rule.ruleName,
                })
              )
            : response.data.UsersRefRulesSettingEntityList.content
                .filter((algo) => !algo.refRule.archived)
                .map((algo) => ({
                  isArchived: algo.refRule.archived,
                  isActivated: algo.isActivated,
                  ruleId: algo.ruleId,
                  ruleGroup: algo.refRule.ruleGroup,
                  parentRefRule: algo.refRule.parentRefRule,
                  ruleName: algo.refRule.ruleName,
                }));

        resolve(res);
      })
      .catch((error) => reject(error));
  });
}

export function getUserDisplaySettings(user, mode, workspace) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getUserDisplaySettingsQuery(user, mode, workspace))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        const reponseObj =
          response.data.UsersDashboardSettingsEntityList.content[0];
        const filtersObj = JSON.parse(reponseObj.filters) || {};
        const isGraphObj = JSON.parse(reponseObj.isGraphRule) || {};
        const isKeyIndicatorObj =
          JSON.parse(reponseObj.isKeyIndicatorRule) || {};

        const filters = Object.keys(filtersObj).reduce((res, slice) => {
          filtersObj[slice].forEach((filter) =>
            res.push({ name: slice, value: filter })
          );
          return res;
        }, []);

        const rules = response.data.RefRulesMonitorEntityList.content;

        const algorithms = Object.keys(isGraphObj).reduce((algos, ruleId) => {
          const rule = rules.find((rule) => rule.ruleCode === ruleId);

          if (!rule?.archived)
            algos.push({
              ruleId,
              ruleGroup: rule?.ruleGroup,
              ruleName: rule?.ruleName,
              isGraph: isGraphObj[ruleId],
              isKeyIndicator: isKeyIndicatorObj[ruleId],
            });
          return algos;
        }, []);

        resolve({ algorithms, filters });
      })
      .catch((error) => reject(error))
  );
}

export function getUserFilters(user) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getUserFiltersQuery(user))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }

        const modeObj =
          response.data.UsersDashboardSettingsEntityList.content.reduce(
            (settings, settingSet) => {
              const filtersObj = JSON.parse(settingSet.filters) || {};

              const filters = Object.keys(filtersObj).reduce((res, slice) => {
                filtersObj[slice].forEach((filter) =>
                  res.push({ name: slice, value: filter })
                );

                return res;
              }, []);

              if (!settings[settingSet.modeIsWorkspace])
                settings[settingSet.modeIsWorkspace] = {};

              settings[settingSet.modeIsWorkspace][settingSet.modeIsDashboard] =
                { filters };

              return settings;
            },
            {}
          );

        resolve(modeObj);
      })
      .catch((error) => reject(error))
  );
}

export function updateAlgorithmsActivated(algorithms, mode, workSpace) {
  const url =
    mode === SCHEDULE
      ? UPDATE_ALGOS_ACTIVATED_SCHEDULE_URL
      : UPDATE_ALGOS_ACTIVATED_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .post(url, algorithms, { params: { workSpace } })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function runScanAlgo(startDate, endDate, workSpaceEnum) {
  const formData = new FormData();
  formData.append('startDate', startDate);
  formData.append('endDate', endDate);
  formData.append('workSpaceEnum', workSpaceEnum);
  const url = RUN_SCAN_ALGO;

  return new Promise((resolve, reject) =>
    axi
      .post(url, formData)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function stopScanAlgo() {
  return new Promise((resolve, reject) =>
    axi
      .post(STOP_SCAN_ALGO)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function downloadFineTunings(mode, workSpaceEnum, user) {
  const url =
    mode === SCHEDULE
      ? GET_FINE_TUNINGS_SCHEDULE_URL
      : GET_FINE_TUNINGS_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: { workSpaceEnum, user },
        responseType: 'blob',
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function uploadFineTunings(file, mode, workSpaceEnum) {
  const url =
    mode === SCHEDULE
      ? SET_FINE_TUNINGS_SCHEDULE_URL
      : SET_FINE_TUNINGS_ANALYTIC_URL;
  const formData = new FormData();
  formData.append('excelFile', file);
  if (mode === SCHEDULE) formData.append('workSpaceEnum', workSpaceEnum);

  return new Promise((resolve, reject) =>
    axi
      .post(url, formData)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const updateFineTuningOperators = async () =>
  axi.post(UPDATE_FINE_TUNINGS_OPERATORS_URL).then(getResult);

/**
 * Run algo by rule ID
 */
export function runAlgo({ ruleId, reportDate, mode, workspaceEnum }) {
  const formData = new FormData();
  formData.append('ruleId', ruleId);
  if (mode !== IRRESPECTIVE) formData.append('reportDate', reportDate);
  formData.append('workSpaceEnum', workspaceEnum);

  const url =
    mode === IRRESPECTIVE ? RUN_ALGO_SIGNAL_URL : RUN_ALGO_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .post(url, formData)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Run all selected algos
 */
export function runAllSelectedAlgos({ reportDate, mode, workspace }) {
  const formData = new FormData();
  formData.append('reportDate', reportDate);
  formData.append('workSpaceEnum', workspace);

  return new Promise((resolve, reject) =>
    axi
      .post(RUN_ALL_SELECTED_ALGOS_ANALYTIC_URL, formData)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get incident by id from gq endpoint
 */
export function getIncidentReportRow(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getIncidentReportRowQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.IncidentReportRowMonitorEntity);
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get incident by id from gq endpoint
 */
export function getIncidentGQ(id) {
  const entityName = `IncidentMonitorEntity`;

  return new Promise((resolve, reject) =>
    graphql
      .query(getIncidentQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        if (response.data[entityName]) {
          resolve({
            ...response.data[entityName],
            statistics: !response.data[entityName].statistics
              ? { statistic: {}, tuning: [] }
              : {
                  ...response.data[entityName].statistics,
                  metrics: JSON.parse(
                    response.data[entityName].statistics.metrics
                  ),
                  tuning: JSON.parse(
                    response.data[entityName].statistics.tuning
                  ),
                },
          });
        } else reject({ message: 'No data' });
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get deal by id
 */
export function getDeal(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getDealQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data[`DealsImportMonitorEntity`]);
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get deal by id
 */
export function getOrder(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getOrderQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data[`OrdersImportMonitorEntity`]);
      })
      .catch((error) => reject(error))
  );
}

export const getOrderCalculated = async (id) =>
  graphql.query(getOrderCalculatedQuery(id)).then((response) => {
    if (response.errors) {
      throw new Error(response.errors[0]);
    }
    return response.data['OrderDealSecondaryEntityList'].content[0];
  });

/**
 * Get ref issues record by ticker and venue
 */
export function getIssueByTickerVenue(ticker, venue) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getIssueByTickerVenueQuery(ticker, venue))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.RefIssuesMonitorEntityList.content[0]);
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get case by id
 */
export function getCase(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getCaseQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.CaseEntity);
      })
      .catch((error) => reject(error))
  );
}

/**
 * Save user filters
 */
export function saveUserFilters({ algorithms, filters, mode, workspace }) {
  return new Promise((resolve, reject) =>
    axi
      .post(
        SAVE_FILTERS_URL,
        {
          filters: filters.reduce((res, filter) => {
            res[filter.name]
              ? res[filter.name].push(filter.value)
              : (res[filter.name] = [filter.value]);
            return res;
          }, {}),
          userRefRulesSettingKeyAndGraphDto: algorithms,
        },
        {
          params: {
            mode,
            workspace,
          },
        }
      )
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Rebuild distincts and get summary from GlobalValue
 */
export function getGlobalSummary() {
  return new Promise((resolve, reject) =>
    graphql
      .query(getGlobalSummaryQuery())
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(
          JSON.parse(response.data.GlobalValueEntityList.content[0].summary)
        );
      })
      .catch((error) => reject(error))
  );
}

/**
 * Rebuild distincts and get filters template from GlobalValue
 */
export function getFiltersTemplate() {
  return new Promise((resolve, reject) =>
    graphql
      .query(getFiltersTemplateQuery())
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(
          JSON.parse(response.data.GlobalValueEntityList.content[0].filters)
        );
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get report data for each rule group (enterprise summary)
 */
export function getEnterpriseSummaryReport({
  columns,
  startDate,
  endDate,
  mode,
  workSpaceEnum,
  modeEnums,
}) {
  const url =
    mode === SCHEDULE
      ? GET_BY_RULE_GROUP_REPORT_SCHEDULE_URL
      : GET_BY_RULE_GROUP_REPORT_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: {
          columns: columns.join(','),
          startDate,
          endDate: format(addDays(Date.parse(endDate), 1), 'yyyy-MM-dd'), // until next day
          workSpaceEnum,
          modeEnums,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get report data for each rule in a rule group
 */
export function getByRuleReport({
  columns,
  ruleGroup,
  startDate,
  endDate,
  mode,
  workSpaceEnum,
  modeEnums,
}) {
  const url =
    mode === SCHEDULE
      ? GET_BY_RULE_REPORT_SCHEDULE_URL
      : GET_BY_RULE_REPORT_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: {
          columns: columns.join(','),
          ruleGroup,
          startDate,
          endDate: format(addDays(Date.parse(endDate), 1), 'yyyy-MM-dd'), // until next day
          workSpaceEnum,
          modeEnums,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get report data for each period in a rule group
 */
export function getByPeriodReport({
  columns,
  ruleGroup,
  priorities,
  startDate,
  endDate,
  mode,
  workSpaceEnum,
}) {
  const url =
    mode === SCHEDULE
      ? GET_BY_PERIOD_REPORT_SCHEDULE_URL
      : GET_BY_PERIOD_REPORT_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: {
          columns: columns.join(','),
          priorities: priorities.join(','),
          ruleGroup,
          startDate,
          endDate: format(addDays(Date.parse(endDate), 1), 'yyyy-MM-dd'), // until next day
          workSpaceEnum,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get report data for each month in a rule group
 */
export function getByMonthReport({
  columns,
  ruleGroup,
  startDate,
  endDate,
  mode,
  workSpaceEnum,
  modeEnums,
}) {
  const url =
    mode === SCHEDULE
      ? GET_BY_MONTH_REPORT_SCHEDULE_URL
      : GET_BY_MONTH_REPORT_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: {
          columns: columns.join(','),
          ruleGroup,
          startDate,
          endDate: format(addDays(Date.parse(endDate), 1), 'yyyy-MM-dd'), // until next day
          workSpaceEnum,
          modeEnums,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get monthly data report for selected algorithms
 */
export function getGraphReport({
  startDate,
  endDate,
  mode,
  workSpaceEnum,
  modeEnums,
}) {
  const url =
    mode === SCHEDULE
      ? GET_GRAPH_REPORT_SCHEDULE_URL
      : GET_GRAPH_REPORT_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: {
          startDate,
          endDate: format(addDays(Date.parse(endDate), 1), 'yyyy-MM-dd'), // until next day
          workSpaceEnum,
          modeEnums: modeEnums.join(','),
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get traders with most incidents
 */
export function getFlaggedReport(
  field,
  startDate,
  endDate,
  mode,
  workSpaceEnum,
  modeEnums
) {
  const url =
    mode === SCHEDULE
      ? GET_FLAGGED_REPORT_SCHEDULE_URL
      : GET_FLAGGED_REPORT_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: {
          columns: field,
          startDate,
          endDate: format(addDays(Date.parse(endDate), 1), 'yyyy-MM-dd'), // until next day
          workSpaceEnum,
          modeEnums,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get incidents count of favourite rules
 */
export function getKriReport({
  startDate,
  endDate,
  mode,
  workSpaceEnum,
  modeEnums,
}) {
  const url =
    mode === SCHEDULE
      ? GET_KRI_REPORT_SCHEDULE_URL
      : GET_KRI_REPORT_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .get(url, {
        params: {
          startDate,
          endDate: format(addDays(Date.parse(endDate), 1), 'yyyy-MM-dd'), // until next day
          workSpaceEnum,
          isFinal: true,
          modeEnums: modeEnums.join(','),
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getNewsByDateRange({ startDate, endDate, venue, ticker }) {
  return new Promise((resolve, reject) =>
    axi
      .get(
        GET_NEWS_URL,
        { params: { startDate, endDate, venue, ticker } },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getNews(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getNewsQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.NewsEntity);
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get fine tunings by Rule ID and username
 */

export function getRuleFineTuning(ruleCode, user, mode, workspace) {
  const query =
    mode === SCHEDULE
      ? getRuleFineTuningScheduleQuery
      : getRuleFineTuningAnalyticQuery;
  return new Promise((resolve, reject) =>
    graphql
      .query(query({ ruleCode, user, workspace }))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        const res = response.data.RefRulesMonitorEntityList.content[0];
        res.tunings = (
          mode === SCHEDULE ? res.tuningsSchedule : res.tuningsAnalytic
        ).map((tune) => {
          return { ...tune, params: JSON.parse(tune.params) };
        });
        resolve(res);
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get fine tuning validation schema by Rule ID
 */

export function getRuleParamsValidation(ruleId) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getRuleParamsValidationQuery(ruleId))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.RefRulesEntityList.content[0]);
      })
      .catch((error) => reject(error))
  );
}

export function saveTuningParams(params, mode, ml) {
  const url =
    mode === SCHEDULE
      ? ml
        ? ALGO_ML_FINE_TUNING_SCHEDULE_URL
        : ALGO_FINE_TUNING_SCHEDULE_URL
      : ml
      ? ALGO_ML_FINE_TUNING_ANALYTIC_URL
      : ALGO_FINE_TUNING_ANALYTIC_URL;

  return new Promise((resolve, reject) =>
    axi
      .post(url, params)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function resetTuningParams(fineTuningId, mode) {
  let url;
  if (!mode) url = RESET_FINE_TUNING_SIGNAL_URL;
  else {
    url =
      mode === SCHEDULE
        ? RESET_FINE_TUNING_SCHEDULE_URL
        : RESET_FINE_TUNING_ANALYTIC_URL;
  }

  return new Promise((resolve, reject) =>
    axi
      .post(url, `"${fineTuningId}"`)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get dashboard market chart parameters
 */
export function getDashboardChartInitialParameters(user, mode, workspace) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getDashboardChartInitialParametersQuery(user, mode, workspace))
      .then((response) => {
        const rulesObj = JSON.parse(
          response.data.UsersDashboardSettingsEntityList.content[0].isGraphRule
        );
        const refRules = response.data.RefRulesMonitorEntityList.content;
        const rules = Object.keys(rulesObj)
          .filter((ruleCode) => rulesObj[ruleCode])
          .map((ruleCode) => {
            const rule = refRules.find(
              (refRule) => refRule.ruleCode === ruleCode
            );
            const { ruleGroup, ruleName } = rule;

            return { ruleCode, ruleGroup, ruleName };
          });
        const initialTicker =
          response.data.DealsImportMonitorEntityList.content[0]?.ticker ||
          response.data.RefIssuesMonitorEntityList.content[0]?.ticker ||
          'na';
        const initialClient =
          response.data.DealsImportMonitorEntityList.content[0]?.client || 'na';
        const initialDateTo =
          response.data.DealsImportMonitorEntityList.content[0]?.dealDtime.split(
            ' '
          )[0] || new Date();

        resolve({ initialTicker, initialClient, rules, initialDateTo });
      })
      .catch((error) => reject(error))
  );
}

export function getChartData({ dateFrom, dateTo, ticker, clients }) {
  const query = clients
    ? getChartDataQuery
    : getChartDataWithoutClientFilterQuery;
  return new Promise((resolve, reject) =>
    graphql
      .query(
        query({
          dateFrom: startOfDay(dateFrom),
          dateTo: endOfDay(dateTo),
          ticker,
          clients,
        })
      )
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteDevDealsOrdersIncidents() {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_DEV_DEALS_ORDERS_INCIDENTS)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteDevNews() {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_DEV_NEWS)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteDevMkt() {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_DEV_MKT)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteDevMktDays() {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_DEV_MKT_DAYS)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteDevIncidentsByRulegroup(rulegroup) {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_DEV_INCIDENTS_BY_RULEGROUP, rulegroup)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function reloadDevMktMinutes() {
  return new Promise((resolve, reject) =>
    axi
      .post(RELOAD_DEV_MKT_MINUTES)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function reloadDevMktDays() {
  return new Promise((resolve, reject) =>
    axi
      .post(RELOAD_DEV_MKT_DAYS)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function reloadDevRefratesSecurities({ startDate, endDate }) {
  return new Promise((resolve, reject) =>
    axi
      .post(RELOAD_DEV_REFRATES_SECURITIES, null, {
        params: {
          startDate,
          endDate,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function reloadDevRefissues() {
  return new Promise((resolve, reject) =>
    axi
      .post(RELOAD_DEV_REFISSUES)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function reloadDevCashRefissues() {
  return new Promise((resolve, reject) =>
    axi
      .post(RELOAD_DEV_CASH_REFISSUES)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function loadDevRefIssuesByRefIssuesFromMoex() {
  return new Promise((resolve, reject) =>
    axi
      .post(LOAD_DEV_REFISSUES_BY_REFISSUES_FROM_MOEX)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function loadDevDealsImport() {
  return new Promise((resolve, reject) =>
    axi
      .post(LOAD_DEV_DEALS_IMPORT)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function loadDevOrdersImport() {
  return new Promise((resolve, reject) =>
    axi
      .post(LOAD_DEV_ORDERS_IMPORT)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateRelatedDealsOrders(startDate, endDate) {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams({ startDate, endDate });
    const url = `${UPDATE_RELATED_DEALS_ORDERS}?${params}`;

    return axi
      .post(url)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export function incidentRegenetateByDate(dateFrom, dateTo) {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams({ dateFrom, dateTo });
    const url = `${REGENERATE_INCIDENTS_BY_DATE}?${params}`;

    return axi
      .post(url)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export function resetFiltersTemplate() {
  return new Promise((resolve, reject) =>
    axi
      .post(RESET_FILTERS_TEMPLATE_URL)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteIncidentsById(id) {
  return new Promise((resolve, reject) =>
    axi
      .delete(DELETE_DEV_INCIDENT_BY_ID, { data: { id } })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get users to assign cases to
 */
export function getCaseUsers(workSpaceEnum) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_CASE_USERS_URL, { params: { workSpaceEnum } })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function createCase({
  subject,
  description,
  assigned,
  type,
  incidentIds,
  workspace,
}) {
  return new Promise((resolve, reject) =>
    axi
      .post(
        CREATE_CASE_URL,
        {
          subject,
          description,
          assigned,
          type,
          status: statusOpen,
          incidentIds,
        },
        { params: { workSpaceEnum: workspace } }
      )
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateCase({
  id,
  subject,
  description,
  assigned,
  caseType,
  caseStatus,
}) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_CASE_URL, {
        id,
        subject,
        description,
        assigned,
        type: caseType,
        status: caseStatus,
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteCase(id) {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_CASE_URL, `"${id}"`)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getCaseActivities(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getCaseActivitiesQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.CaseActivityEntityList.content);
      })
      .catch((error) => reject(error))
  );
}

export function createCaseActivity({
  caseId,
  type,
  status,
  started,
  plannedEnd,
  description,
}) {
  return new Promise((resolve, reject) =>
    axi
      .post(CREATE_CASE_ACTIVITY_URL, {
        caseId,
        type,
        status,
        started,
        plannedEnd,
        description,
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateCaseActivity({
  id,
  type,
  status,
  started,
  plannedEnd,
  description,
  fileIds,
}) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_CASE_ACTIVITY_URL, {
        id,
        type,
        status,
        started,
        plannedEnd,
        description,
        fileIds,
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteCaseActivity(id) {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_CASE_ACTIVITY_URL, `"${id}"`, { responseType: 'blob' })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function addIncidentsToCase(caseId, incidentIds) {
  return new Promise((resolve, reject) =>
    axi
      .post(ADD_INCIDENTS_TO_CASE_URL, {
        id: caseId,
        incidentIds,
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function removeIncidentsFromCase(caseId, incidentIds) {
  return new Promise((resolve, reject) =>
    axi
      .post(REMOVE_INCIDENTS_FROM_CASE_URL, {
        id: caseId,
        incidentIds,
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getCaseReport(ids) {
  return new Promise((resolve, reject) =>
    axi
      .post(GET_CASE_REPORT_URL, Object.values(ids), { responseType: 'blob' })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getCaseIncidentReport(caseIds, reportForm) {
  return new Promise((resolve, reject) =>
    axi
      .post(
        GET_CASE_INCIDENT_REPORT_URL,
        {
          caseIds,
          reportForm,
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getIncidentReport(incidentIds, reportForm) {
  return new Promise((resolve, reject) =>
    axi
      .post(
        GET_INCIDENT_REPORT_URL,
        {
          incidentIds,
          reportForm,
        },
        { responseType: 'blob' }
      )
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Common file upload function
 */
export function uploadFile({ url, ...params }) {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (params[key]) formData.append(key, params[key]);
  });

  return new Promise((resolve, reject) =>
    axi
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Upload file and get it's ID for further processing
 */
export function uploadCaseFile(file) {
  return uploadFile({ file, url: GET_CASE_FILE_URL });
}

/**
 * Download file by id
 */
export function getCaseFileById(id) {
  return new Promise((resolve, reject) =>
    axi
      .get(`${GET_CASE_FILE_URL}/${id}`, { responseType: 'blob' })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Delete file by id
 */
export function deleteCaseFileById(id) {
  return new Promise((resolve, reject) =>
    axi
      .delete(`${GET_CASE_FILE_URL}/${id}`)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function saveRefIssues(issues) {
  return new Promise((resolve, reject) =>
    axi
      .post(FIX_ISSUES_URL, issues)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getAssets() {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_ASSETS)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getAssetClasses() {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_ASSET_CLASSES)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getBusinessLines() {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_BUSINESSLINES)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateRefCounterparty(data) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_REF_COUNTERPARTY_URL, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateRefInsiders(data) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_REF_INSIDERS_URL, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateRefIssues(data) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_REF_ISSUES_URL, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateRefStaff(data) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_REF_STAFF_URL, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get history of launched tasks
 */
export function getTaskStatus(workSpaceEnum, size, page = 0) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_TASKS_URL, {
        params: { sort: 'updateAt,DESC', size, page, workSpaceEnum },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getScanStatus(workSpaceEnum) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_TASKS_URL, {
        params: {
          workSpaceEnum,
          taskName: 'Forced start Run schedule algo per range task',
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

// get task by id
export function getTaskByID(id) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_TASK_BY_ID.replace(':id', id))
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get settings for business-admin panel
 */

export function getBusinessSettings(workSpaceEnum) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_BUSINESS_SETTINGS_URL, { params: { workSpaceEnum } })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Update settings for business-admin panel
 */

export function updateBusinessSettings(settings, workSpaceEnum) {
  return new Promise((resolve, reject) =>
    axi
      .post(GET_BUSINESS_SETTINGS_URL, settings, { params: { workSpaceEnum } })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Get users for admin panel
 */

export function getAdminUsers() {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_ADMIN_USERS_URL)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Create new user
 */

export function createUser({ username, password, fullName, roles, email }) {
  return new Promise((resolve, reject) =>
    axi
      .post(CREATE_USER_URL, { username, password, fullName, roles, email })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

/**
 * Update user
 */
export function updateUser({ username, password, fullName, email, roles }) {
  return axi
    .put(EDIT_FULL_USER_URL, {
      username,
      password,
      fullName,
      email,
      roles,
    })
    .then(getResult);
}

/**
 * Disable user by username
 */

export function disableUser(username) {
  const formData = new FormData();
  formData.append('username', username);

  return new Promise((resolve, reject) =>
    axi
      .put(DISABLE_USER_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function enableUser(username) {
  const formData = new FormData();
  formData.append('username', username);

  return new Promise((resolve, reject) =>
    axi
      .put(ENABLE_USER_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function resetUsersDefaultSettings(users) {
  return new Promise((resolve, reject) =>
    axi
      .post(RESET_USERS_DEFAULT_SETTINGS_URL, users, { responseType: 'blob' })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function stopAlgorithm(taskId) {
  const formData = new FormData();
  formData.append('taskId', taskId);
  return new Promise((resolve, reject) =>
    axi
      .post(STOP_RUNNING_ALGO_ANALYTIC_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function loadDevRefIssuesByTickers(tickers) {
  return new Promise((resolve, reject) => {
    axi
      .post(LOAD_DEV_REF_ISSUES_BY_TICKERS, tickers)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export function loadDevMktByTickers({ tickers, startDate, endDate }) {
  return new Promise((resolve, reject) => {
    axi
      .post(LOAD_DEV_MKT_BY_TICKERS, tickers, {
        params: { startDate, endDate },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export function loadDevMktDaysByTickers({ tickers, startDate, endDate }) {
  return new Promise((resolve, reject) => {
    axi
      .post(LOAD_DEV_MKT_DAYS_BY_TICKERS, tickers, {
        params: { startDate, endDate },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export function verifyCustomRule(data, isRuleExist) {
  return new Promise((resolve, reject) =>
    axi
      .post(`${VERIFY_CUSTOM_ALGORITHM}?isRuleExist=${isRuleExist}`, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function stopVerifyCustomRule(id) {
  return new Promise((resolve, reject) =>
    axi
      .delete(STOP_VERIFY_CUSTOM_ALGORITHM, {
        params: { id },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function addNewCustomRule(data, workSpaceEnum) {
  return new Promise((resolve, reject) =>
    axi
      .post(SANDBOX, data, {
        params: { workSpaceEnum },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getCustomRules(page, size, sort) {
  return new Promise((resolve, reject) =>
    axi
      .get(SANDBOX, {
        params: {
          page: page || 0,
          size: size || 20,
          sort: sort || 'ruleCode,asc',
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getCustomRule(id) {
  return new Promise((resolve, reject) =>
    axi
      .get(SANDBOX + id)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function runCustomRule(id) {
  return new Promise((resolve, reject) =>
    axi
      .post(RUN_CUSTOM_ALGORITHM + id)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteCustomRule(ruleCode) {
  return new Promise((resolve, reject) =>
    axi
      .delete(SANDBOX, {
        params: { ruleCode },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateCustomRule(data, id) {
  return new Promise((resolve, reject) =>
    axi
      .put(SANDBOX + id, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function addDraftCustomRule(data, workSpaceEnum) {
  return new Promise((resolve, reject) =>
    axi
      .post(DRAFT_CUSTOM_RULE, data, {
        params: { workSpaceEnum },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getDraftsCustomRule(workSpaceEnum, page, size) {
  return new Promise((resolve, reject) =>
    axi
      .get(DRAFT_CUSTOM_RULE, {
        params: {
          workSpaceEnum,
          isCurrentUser: true,
          page: page || 0,
          size: size || 10,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function deleteDraftCustomRule(id) {
  return new Promise((resolve, reject) =>
    axi
      .delete(DRAFT_CUSTOM_RULE, {
        params: {
          draftId: id,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const getAdminProperties = () =>
  axi
    .get(GET_ADMIN_PROPERTIES, {
      params: {
        name: 'sessionInactiveIntervalInSec,server.user_max_sessions,auditSettings',
      },
    })
    .then((res) => {
      return {
        ...res.data,
        auditSettings: JSON.parse(res.data.auditSettings),
      };
    });

export const setLogoutTimings = async (time) =>
  axi
    .post(GET_ADMIN_PROPERTIES, { sessionInactiveIntervalInSec: time })
    .then(getResult);

export const setUserMaxSessions = async (time) =>
  axi
    .post(GET_ADMIN_PROPERTIES, { 'server.user_max_sessions': time })
    .then(getResult);

export const setRootUserPassword = async (password) =>
  axi
    .post(GET_ADMIN_PROPERTIES, { rootUserPassword: window.btoa(password) })
    .then(getResult);

export const stopMaintenance = async () =>
  axi.post(STOP_MAINTENANCE_URL).then(getResult);

export const startMaintenance = async () =>
  axi.post(START_MAINTENANCE_URL).then(getResult);

export const getMaintenance = async () =>
  axi.get(GET_MAINTENANCE_URL).then(getResult);

export const updateIncidentManagement = async (data) =>
  axi.post(UPDATE_INCIDENT_MANAGEMENT, data).then(getResult);

export const getIncidentManagmentHistory = async (
  id,
  page,
  size,
  sort = 'createdAt,desc'
) =>
  axi
    .get(`${FIND_INCIDENT_MANAGMENT_HISTORY}/${id}`, {
      params: {
        sort: sort,
        page: page || 0,
        size: size || 10,
      },
    })
    .then((res) => {
      return {
        content: res.data.content.map((rec) => ({
          createdAt: rec.createdAt,
          ...JSON.parse(rec.currentJson),
        })),
        total: res.data.total,
        pageable: res.data.pageable,
      };
    });

export const getTaskHistory = async (id, page, size) => {
  return axi
    .get(GET_TASK_HISTORY_BY_ID, {
      params: {
        entityId: id,
        page: page || 0,
        size: size || 10,
        sort: 'createdAt,desc',
      },
    })
    .then((res) => {
      const result = {
        ...res,
        data: {
          ...res.data,
          content: res.data.content.map((item) => ({
            ...item,
            ...JSON.parse(item.currentJson),
          })),
        },
      };

      return getResult(result);
    });
};

export const areDealsInIncident = (id) =>
  graphql.query(areDealsInIncidentQuery(id)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);
    return res.data.DealsImportMonitorEntityList.content.length !== 0;
  });

export const areOrdersInIncident = (id) =>
  graphql.query(areOrdersInIncidentQuery(id)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);
    return res.data.OrdersImportMonitorEntityList.content.length !== 0;
  });

export const getRuleDetails = () =>
  graphql.query(getRuleDetailsQuery()).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    const rulesObj = res.data.RefRulesMonitorEntityList.content.reduce(
      (acc, refRule) => {
        acc[refRule.ruleCode] = {
          ruleGroup: refRule.ruleGroup,
          ruleName: refRule.ruleName,
        };
        return acc;
      },
      {}
    );

    return rulesObj;
  });

export const getRoles = () => axi.get(GET_TAFS_ROLES_URL).then(getResult);

export const getRolePermissions = (role) => {
  return axi
    .get(GET_ROLE_PERMISSIONS_URL.replace(':roleName', role))
    .then((res) => parsePermissions(getResult(res), true));
};

export const changeRole = (role, permissions) =>
  axi.put(GET_ROLE_PERMISSIONS_URL.replace(':roleName', role), permissions);

export const getRefHistory = async (refName, page, size) =>
  axi
    .get(GET_REF_HISTORY.replace('{refName}', `ref${refName}`), {
      params: {
        page: page || 0,
        size: size || 10,
        sort: 'createdAt,desc',
      },
    })
    .then((res) => getResult(res));

export const createAlgoTemplate = async (
  newRuleCode,
  newRuleName,
  parentRuleCode,
  workSpace
) =>
  axi
    .post(CREATE_ALGO_TEMPLATE_URL, {
      newRuleCode,
      newRuleName,
      parentRuleCode,
      workSpace,
    })
    .then(getResult);

export const deleteAlgoTemplate = async (ruleCode) =>
  axi
    .delete(DELETE_ALGO_TEMPLATE_URL, {
      params: { ruleCode },
    })
    .then(getResult);

export const recoverAlgoTemplate = async (ruleCode) =>
  axi
    .put(RECOVER_ALGO_TEMPLATE_URL, null, {
      params: { ruleCode },
    })
    .then(getResult);

export const validateNewRuleCode = (ruleCode) =>
  graphql.query(getRuleCodesQuery()).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);
    return !res.data.RefRulesMonitorEntityList.content
      .map((rule) => rule.ruleCode)
      .includes(ruleCode);
  });

export const forceAlgoRun = (reportDate, workSpaceEnum) =>
  axi.post(FORCE_UPDATE_ALGO_RUN, null, {
    params: {
      reportDate,
      workSpaceEnum,
    },
  });

export function downloadUsersCsv() {
  return axi
    .get(GET_USERS_CSV_URL, {
      responseType: 'blob',
    })
    .then(getResult);
}

export const createWatchList = (workSpaceEnum, name, client) =>
  axi
    .post(CREATE_WATCH_LIST, { client, name }, { params: { workSpaceEnum } })
    .then(getResult);

export const updateWatchList = (watchListId, name, client) =>
  axi.put(UPDATE_WATCH_LIST, { watchListId, client, name }).then(getResult);

export const addToWatchList = (idWatchList, client) =>
  axi
    .post(ADD_TO_WATCH_LIST, null, { params: { idWatchList, client } })
    .then(getResult);

export const getWatchListById = (id) =>
  graphql
    .query(getWatchListByIdQuery(id))
    .then((res) => res.data.WatchListMonitorEntity);

export const getWatchListsByClient = (client, workspace) =>
  graphql.query(getWatchListsByClientQuery(client, workspace)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.WatchListMonitorEntityList.content;
  });

export const getClientsByWatchList = (name, workspace) =>
  graphql.query(getClientsByWatchListQuery(name, workspace)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.WatchListMonitorEntityList?.content[0]
      ?.refCounterpartyEntityList;
  });

export const getWatchListChartData = (watchListName, modeIncident) =>
  axi
    .get(GET_WATCHLIST_CHART_DATA_URL, {
      params: {
        watchListName,
        modeIncident,
      },
    })
    .then((res) => getResult(res));

export const updateModulesLogging = (data) => {
  return axi.put(UPDATE_MODULES_LOGGING, data).then(getResult);
};

export function getCounterparty(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getCounterpartyQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.RefCounterpartyMonitorEntityList.content[0]);
      })
      .catch((error) => reject(error))
  );
}

export function getClientStatistics(client, workspace) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getClientStatisticsQuery(workspace, client))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.ClientStatisticEntityList.content[0]);
      })
      .catch((error) => reject(error))
  );
}

export function getIncidentsByClient(client, workspace) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getIncidentsByClientQuery(client, workspace))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.IncidentReportRowMonitorEntityList.content);
      })
      .catch((error) => reject(error))
  );
}

export function getInsider(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getInsiderQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.RefInsidersMonitorEntity);
      })
      .catch((error) => reject(error))
  );
}

export function getStaff(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getStaffQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.RefStaffEntity);
      })
      .catch((error) => reject(error))
  );
}

export const getClientActivity = (client, dateForPosition, ticker, venue) =>
  axi
    .get(
      !!ticker ? GET_CLIENT_ACTIVITY_WITH_TICKER_URL : GET_CLIENT_ACTIVITY_URL,
      {
        params: {
          client,
          dateForPosition,
          ticker,
          venue,
        },
      }
    )
    .then(getResult);

export function getClientConnectionsStats(clientId, workSpaceEnum) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_CLIENT_CONNECTION_STATS, {
        params: {
          client: clientId,
          workSpaceEnum,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getClientConnectionsStatsPerDates(
  startDate,
  endDate,
  clientId,
  workSpaceEnum
) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_CLIENT_CONNECTION_STATS_PER_DATES, {
        params: {
          startDate,
          endDate,
          client: clientId,
          workSpaceEnum,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getClientConnectionsStatsForPeriod(
  clientId,
  period,
  workSpaceEnum
) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_CLIENT_CONNECTION_STATS_ENUM, {
        params: { period, client: clientId, workSpaceEnum },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getConnectionStatsReport(startDate, endDate, watchList) {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams({ startDate, endDate, watchList });
    const url = `${DOWNLOAD_CONNECTIONS_STATS_REPORT}?${params}`;

    return axi
      .get(url, { responseType: 'blob' })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export function getAffiliationReport(watchList) {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams({ watchList });
    const url = `${DOWNLOAD_AFFILIATION_REPORT}?${params}`;

    return axi
      .get(url, { responseType: 'blob' })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export function getCounterpartyAffiliates(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getCounterpartyAffiliatedReport(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(
          response.data.RefCounterpartyAffiliatedGroupEntityList.content[0]
        );
      })
      .catch((error) => reject(error))
  );
}

export function getAffiliationChains(id, columnName) {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_AFIILIATION_CHAINS, {
        params: { client: id, mainColumnName: columnName },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const deleteWatchLists = async (watchListIds) =>
  axi.delete(WATCH_LIST_URL, { data: watchListIds }).then(getResult);

export function downloadXls(query, language) {
  return axi
    .post(GENERATE_XLS, query, {
      params: { userLanguage: language.toUpperCase() },
      responseType: 'blob',
    })
    .then(getResult);
}

export function getAppVersion() {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_APP_VERSION)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const getOrderId = (ordersUniqueHash) => {
  return graphql.query(getOrderIdQuery(ordersUniqueHash)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);
    return res.data.OrdersImportMonitorEntityList.content[0]?.id;
  });
};

export const getDealId = (dealsUniqueHash) => {
  return graphql.query(getDealIdQuery(dealsUniqueHash)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);
    return res.data.DealsImportMonitorEntityList.content;
  });
};

export function getIncidentSerialId(idList) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getIncidentSerialIdQuery(idList))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.IncidentReportRowMonitorEntityList.content);
      })
      .catch((error) => reject(error))
  );
}

export const getGlobalFilters = ({ mode, user, workspace }) => {
  const query =
    mode === SCHEDULE
      ? getGlobalTickersScheduleQuery(workspace)
      : getGlobalTickersAnalyticQuery(user);

  const entity =
    mode === SCHEDULE
      ? 'UsersRefRulesScheduleSettingsEntityList'
      : 'UsersRefRulesAnalyticSettingsEntityList';

  return graphql.query(query).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data[entity].content[0];
  });
};

export const getIssuesByTicker = (tickers) => {
  return graphql.query(getIssuesByTickerQuery(tickers)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.RefIssuesMonitorEntityList.content;
  });
};

export const getCounterpartiesById = (clients) => {
  return graphql.query(getCounterpartiesByIdQuery(clients)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.RefCounterpartyMonitorEntityList.content;
  });
};

export function setGlobalFilters({ globalFilter, value, mode, workspace }) {
  const url = getGlobalFilterUrl(globalFilter, mode);
  const body = mode === SCHEDULE ? { value, workSpace: workspace } : value;

  return new Promise((resolve, reject) =>
    axi
      .post(url, body)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function recalculateSignalsById(id, workSpace) {
  return new Promise((resolve, reject) =>
    axi
      .post(RECALCULATE_SIGNALS, JSON.stringify(id), { params: { workSpace } })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getRuleGroupFineTunings(ruleGroup, workSpace) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getRuleGroupFineTuningsSignalQuery(ruleGroup, workSpace))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        const res = response.data.RefRulesMonitorEntityList.content;
        if (res) {
          res.forEach((item) => {
            item.tunings = item.tuningsSignal.map((tune) => ({
              ...tune,
              params: JSON.parse(tune.params),
            }));
          });
          resolve(res);
        } else {
          reject();
        }
      })
      .catch((error) => reject(error))
  );
}

export function getRuleGroupParamsValidation(ruleId) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getRuleGroupParamsValidationQuery(ruleId))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.RefRulesEntityList.content);
      })
      .catch((error) => reject(error))
  );
}

export function saveSignalTuningParams(params) {
  return new Promise((resolve, reject) =>
    axi
      .post(ALGO_FINE_TUNING_SIGNAL_URL, params)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getSignalInfoReportRow(id, workspace) {
  const query =
    workspace === workspaceObj.ANALYST.id
      ? getSignalInfoForAnalyticIBQuery
      : getSignalInfoForAnalyticComplianceQuery;
  return new Promise((resolve, reject) =>
    graphql
      .query(query(id))
      .then((res) => {
        if (res.errors) {
          throw new Error(res.errors[0]);
        }
        resolve(res.data.IncidentReportRowMonitorEntity);
      })
      .catch((error) => reject(error))
  );
}

export function getMMSignalInfoReportRow(id, workspace) {
  const query =
    workspace === workspaceObj.ANALYST.id
      ? getMMSignalInfoForAnalyticIBQuery
      : getMMSignalInfoForAnalyticComplianceQuery;
  return new Promise((resolve, reject) =>
    graphql
      .query(query(id))
      .then((res) => {
        if (res.errors) {
          throw new Error(res.errors[0]);
        }
        resolve(res.data.IncidentReportRowMonitorEntity);
      })
      .catch((error) => reject(error))
  );
}

export const createOverlapReport = (typeReport, startDate, endDate) => {
  return new Promise((resolve, reject) =>
    axi
      .post(CREATE_OVERLAP_REPORT, null, {
        params: {
          typeReport,
          startDate,
          endDate,
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
};

export const getOverlapClientsChartData = (reportId) =>
  graphql.query(getOverlapClientsChartDataQuery(reportId)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.OverlapCounterpartyEntityList.content;
  });

export const getOverlapDealsChartData = (reportId) =>
  graphql.query(getOverlapDealsChartDataQuery(reportId)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.OverlapDealsEntityList.content;
  });

export const getOverlapOrdersChartData = (reportId) =>
  graphql.query(getOverlapOrdersChartDataQuery(reportId)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.OverlapOrdersEntityList.content;
  });

export const getUnprocessedIncidentsChartData = (id) =>
  graphql.query(getUnprocessedIncidentsChartDataQuery(id)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return {
      data: res.data.ReportEntity.overlapIncidentOldVisualData,
      hardness: res.data.UnusedIncidentEntityList.content[0]?.hardness,
    };
  });

export function downloadOverlapReport(typeReport, reportId) {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams({ typeReport, reportId });
    const url = `${
      typeReport === INCIDENTS_DRP
        ? DOWNLOAD_UNCALCULATED_INCIDENTS_REPORT
        : DOWNLOAD_OVERLAP_REPORT
    }?${params}`;

    return axi
      .get(url, { responseType: 'blob' })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error));
  });
}

export const getOverlapIncidentsId = () => {
  return new Promise((resolve, reject) =>
    axi
      .get(GET_OVERLAP_INCIDENTS)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
};

export const getOverlapIncidents = (incidents) => {
  return graphql.query(getOverlapIncidentsQuery(incidents)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.IncidentReportRowMonitorEntityList.content;
  });
};

export const updateOverlapReportStatus = async (id, status) =>
  axi.post(UPDATE_OVERLAP_REPORT_STATUS_URL, null, {
    params: { id, status },
  });

export const updateOverlapReportDetailStatus = async (id, typeReport, status) =>
  axi.post(UPDATE_OVERLAP_REPORT_SUB_STATUS_URL, null, {
    params: { id, typeReport, status },
  });

export const getAvailableRuleGroups = () => {
  return graphql.query(getAvailableRuleGroupsQuery()).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);
    const ruleGroups = res.data.WorkSpaceEntityList.content.reduce(
      (ruleGroupObj, ws) => {
        ruleGroupObj[ws.workSpaceName] = uniq(
          ws.refRulesMonitorEntities.map((rule) => rule.ruleGroup)
        ).filter((rg) => GPB_MODE || rg !== effectivenessRuleGroup.id);
        return ruleGroupObj;
      },
      {}
    );
    return ruleGroups;
  });
};

export const getIncidentJudgement = (id) =>
  graphql.query(getIncidentJudgementQuery(id)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.StatisticsIncidentEntity?.description;
  });

export const getCaseDocReport = (id) =>
  new Promise((resolve, reject) =>
    axi
      .post(GET_CASE_DOC_REPORT_URL.replace(':id', id), null, {
        responseType: 'blob',
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );

export const getRulesEditUsers = async (workSpaceEnum) => {
  return axi
    .get(GET_RULES_EDIT_USERS, { params: { workSpaceEnum } })
    .then(getResult);
};

export function calculateRanking(ruleCode, workSpace, queryFilters) {
  return new Promise((resolve, reject) =>
    axi
      .post(RUN_INCIDENT_RANKING, getRankingIncidentsQuery(queryFilters), {
        params: { ruleCode, workSpace },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const getOptimizationTaskByRulecode = async (ruleCode, workSpaceEnum) =>
  axi
    .get(
      GET_OPTIMIZATION_TASK_BY_RULECODE_URL.replace('<ruleCode>', ruleCode),
      {
        params: { sort: 'updateAt,DESC', size: '1', workSpaceEnum },
      }
    )
    .then((res) => Array.isArray(res?.data?.content) && res.data.content[0]);

export const runFineTuningOptimization = async (ruleCode, workSpace) =>
  axi.post(RUN_FINE_TUNING_OPTIMIZATION_URL, { ruleCode, workSpace });

export const getFineTuningValueFromPercentile = async (
  ruleCode,
  workSpace,
  values
) =>
  axi.post(GET_FINE_TUNING_VALUE_FROM_PERCENTILE_URL, {
    ruleCode,
    workSpace,
    values,
  });

export const getFineTuningPercentileFromValue = async (
  ruleCode,
  workSpace,
  values
) =>
  axi.post(GET_FINE_TUNING_PERCENTILE_FROM_VALUE_URL, {
    ruleCode,
    workSpace,
    values,
  });

export const getMlChartUnflaggedData = async (
  ruleCode,
  oldTuningValues,
  newTuningValues,
  workSpace
) =>
  axi.post(GET_ML_CHART_UNFLAGGED_DATA_URL, {
    ruleCode,
    oldTuningValues,
    newTuningValues,
    workSpace,
  });

export const getMlChartFlaggedData = async (
  ruleCode,
  oldTuningValues,
  newTuningValues,
  workSpace
) =>
  axi.post(GET_ML_CHART_FLAGGED_DATA_URL, {
    ruleCode,
    oldTuningValues,
    newTuningValues,
    workSpace,
  });

export const getMarketInputsDataByRule = (ruleCode) => {
  return graphql.query(getMarketInputsByRuleQuery(ruleCode)).then((res) => {
    if (res.errors) throw new Error(res.errors[0]);

    return res.data.IncidentReportRowMonitorEntityList.content;
  });
};

export const getMlLineChartData = async (data) =>
  axi.post(GET_ML_CHART_CALC_DATA_URL, data).then(getResult);

export const uploadMLSettings = (file) => {
  return uploadFile({ file, url: POST_ML_SETTINGS_FILE_URL });
};

export const getMLPivot = (ruleCodes) =>
  axi
    .get(GET_ML_PIVOT, {
      params: { ruleCodes },
    })
    .then(getResult);

export function updateIndexes(data) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_REF_INDEXES_URL, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateFixingInterest(data) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_REF_FIXING_INTEREST_URL, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateMoexFx(data) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_REF_MOEX_FX_URL, data)
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const getUncalculatedIncidentsReport = async (dateStart, dateFinish) =>
  axi
    .post(GET_UNCALCULATED_INCIDENTS_REPORT, null, {
      params: { dateStart, dateFinish },
    })
    .then(getResult);

export const saveUncalculatedIncidentsReport = async (
  dateStart,
  dateFinish,
  hardness
) =>
  axi
    .get(SAVE_UNCALCULATED_INCIDENTS_REPORT, {
      params: {
        typeReport: INCIDENTS_DRP,
        statusReport: 'NEW',
        dateStart,
        dateFinish,
        hardness,
      },
    })
    .then(getResult);

export const getAuditReportDeals = async ({
  dateStart,
  dateFinish,
  client,
  tickers,
  filters,
  ruleId,
}) => {
  return axi
    .get(GET_AUDIT_DEALS_REPORT, {
      params: {
        dateStart,
        dateFinish,
        client,
        tickers,
        filters,
        ruleId,
      },
    })
    .then(getResult);
};

export const getAuditReportOrders = async ({
  dateStart,
  dateFinish,
  client,
  tickers,
  filters,
  ruleId,
}) => {
  return axi
    .get(GET_AUDIT_ORDERS_REPORT, {
      params: {
        dateStart,
        dateFinish,
        client,
        tickers,
        filters,
        ruleId,
      },
    })
    .then(getResult);
};

export const getAuditReportIncidents = async ({
  dateStart,
  dateFinish,
  client,
  tickers,
  filters,
}) => {
  return axi
    .get(GET_AUDIT_INCIDENTS_REPORT, {
      params: {
        dateStart,
        dateFinish,
        client,
        tickers,
        filters,
      },
    })
    .then(getResult);
};

export const getAuditReportSignals = async ({
  dateStart,
  dateFinish,
  client,
  tickers,
  filters,
}) => {
  return axi
    .get(GET_AUDIT_SIGNALS_REPORT, {
      params: {
        dateStart,
        dateFinish,
        client,
        tickers,
        filters,
      },
    })
    .then(getResult);
};

export const downloadAuditReport = async ({
  dateStart,
  dateFinish,
  client,
  tickers,
  filters,
  ruleId,
}) => {
  return axi
    .get(DOWNLOAD_AUDIT_DEALS_ORDERS_REPORT, {
      params: {
        dateStart,
        dateFinish,
        client,
        tickers,
        filters,
        ruleId,
      },
      responseType: 'blob',
    })
    .then(getResult);
};

export const getAuditReportById = async (id, type) => {
  const url =
    type === auditReportTypes.deals
      ? GET_AUDIT_DEALS_REPORT
      : GET_AUDIT_ORDERS_REPORT;

  return axi.get(`${url}/${id}`).then(getResult);
};

export const downloadAuditReportById = async (id, type) => {
  const url =
    type === auditReportTypes.deals
      ? DOWNLOAD_AUDIT_DEALS_REPORT
      : DOWNLOAD_AUDIT_ORDERS_REPORT;

  return axi
    .get(`${url}/${id}`, {
      responseType: 'blob',
    })
    .then(getResult);
};

export function updateAffiliationField({ id, affiliatedCustom }) {
  return axi
    .post(UPDATE_AFFILIATION_FIELD_URL, {
      id,
      affiliatedCustom,
    })
    .then(getResult);
}

export function runAffiliatedStatistics(client, workSpace) {
  return axi
    .post(RUN_AFFILIATED_STATISTICS_URL, null, {
      params: { client, workSpace },
    })
    .then(getResult);
}

export const updateUserReport = async (username, isSendingReport) =>
  axi
    .post(EDIT_USER_REPORT_URL, null, { params: { username, isSendingReport } })
    .then(getResult);

export const updateUserReportFilter = async (username, isSendingReportFilter) =>
  axi
    .post(EDIT_USER_REPORT_FILTER_URL, null, {
      params: { username, isSendingReportFilter },
    })
    .then(getResult);

export const updateUserReportConnection = async (
  username,
  isSendingReportStatConnection
) =>
  axi
    .post(EDIT_USER_REPORT_CONNECTION_URL, null, {
      params: { username, isSendingReportStatConnection },
    })
    .then(getResult);

export const updateUserReportAffiliated = async (
  username,
  isSendingReportAffiliated
) =>
  axi
    .post(EDIT_USER_REPORT_AFFILIATED_URL, null, {
      params: { username, isSendingReportAffiliated },
    })
    .then(getResult);

export function getCounterpartyUuid(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getCounterpartyUuidQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }

        resolve(response.data.RefCounterpartyMonitorEntityList.content[0]);
      })
      .catch((error) => reject(error))
  );
}

export function getReferenceFiles(id, workspace) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getReferenceFilesQuery(id, workspace))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }

        resolve(response.data.ReferenceFilesEntityList.content);
      })
      .catch((error) => reject(error))
  );
}

export function uploadRefDocument(params) {
  return uploadFile({ ...params, url: UPLOAD_REF_DOCUMENT });
}

export function deleteRefDocument(id) {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_REF_DOCUMENT, null, {
        params: { fileId: id },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function updateClientConclusion({
  id,
  relatedClient,
  date,
  comment,
  workSpace,
}) {
  return new Promise((resolve, reject) =>
    axi
      .post(UPDATE_CLIENT_CONCLUSION, {
        id,
        relatedClient,
        date,
        comment,
        workSpace,
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export const getRefDocument = (id) =>
  new Promise((resolve, reject) =>
    axi
      .get(DOWNLOAD_REF_DOCUMENT.replace(':id', id), {
        responseType: 'blob',
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );

export function getClientConclusion(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getClientConclusionQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.ClientConclusionEntityList.content);
      })
      .catch((error) => reject(error))
  );
}

export function deleteClientConclusion(id) {
  return new Promise((resolve, reject) =>
    axi
      .post(DELETE_CLIENT_CONCLUSION.replace(':id', id), null, {
        responseType: 'blob',
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
}

export function getClientAndTicker(type, id) {
  const query =
    type === auditReportTypes.deals
      ? getDealsClientAndTickerQuery
      : getOrdersClientAndTickerQuery;
  const entity =
    type === auditReportTypes.deals
      ? 'DealsImportMonitorEntityList'
      : 'OrdersImportMonitorEntityList';

  return new Promise((resolve, reject) =>
    graphql
      .query(query(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data[entity].content[0]);
      })
      .catch((error) => reject(error))
  );
}

export const createCaseRegulatorReport = async (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return new Promise((resolve, reject) =>
    axi
      .post(CREATE_CASE_REGULATOR_REPORT, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
};

export const uploadUserFile = async (params) => {
  return uploadFile({ ...params, url: ADD_USER_FILE_URL });
};

export const uploadInputFile = async (params) => {
  return uploadFile({ ...params, url: ADD_INPUT_FILE_URL });
};

export const removeRequestHistoryFile = async (requestEntityId, type) => {
  const url =
    type === 'inputFile' ? DELETE_INPUT_FILE_URL : DELETE_USER_FILE_URL;

  return new Promise((resolve, reject) =>
    axi
      .post(url, JSON.stringify(requestEntityId))
      .then((response) => {
        resolve(getResult(response));
      })
      .catch((error) => reject(error))
  );
};

export function getCustomRuleDraft(id) {
  return new Promise((resolve, reject) =>
    graphql
      .query(getCustomRuleDraftQuery(id))
      .then((response) => {
        if (response.errors) {
          throw new Error(response.errors[0]);
        }
        resolve(response.data.DraftCustomRefRulesEntity);
      })
      .catch((error) => reject(error))
  );
}
