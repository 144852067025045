import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { initStateWithPrevTab } from 'redux-state-sync';
import throttle from 'lodash/throttle';

import baseReducer from './reducers';
import stateSyncMiddlware from './middlewares/stateSync';
import { loadState, saveState } from '../utils';

const persistedState = loadState();

const store = createStore(
  baseReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk, stateSyncMiddlware()))
);

// init state with other tabs
initStateWithPrevTab(store);

/*
  here we subscribing for every store change
  and process them in chunks accumulated for 1 sec
*/
store.subscribe(
  throttle(() => {
    const state = store.getState();
    const {
      user,
      reports,
      sideMenu,
      charts,
      cases,
      status,
      rules,
      auth,
      overlapReports,
      oneClickAudit,
    } = state;

    saveState({
      user,
      reports,
      sideMenu,
      charts,
      cases,
      status,
      rules,
      auth,
      overlapReports,
      oneClickAudit,
    });
  }, 1000)
);

export default store;
