import format from 'date-fns/format';

import { getQueryArray } from '@tafs/utils';

const storageKey = 'stored-link-data';

export const urlParam = 'stateId';

export const store = (recordId, recordData) => {
  const storedData = JSON.parse(localStorage.getItem(storageKey));
  const today = format(new Date(), 'yyyy-MM-dd');

  localStorage.setItem(
    storageKey,
    JSON.stringify({
      [today]: { ...(storedData && storedData[today]), [recordId]: recordData },
    })
  );
};

export const extract = (recordId) => {
  const storedData = JSON.parse(localStorage.getItem(storageKey));
  const today = format(new Date(), 'yyyy-MM-dd');

  return storedData?.[today]?.[recordId] || {};
};

export const getFilteredUrl = (pathname, search) => {
  const filteredParams = getQueryArray(search).filter(
    (param) => param.name !== urlParam
  );

  if (filteredParams.length === 0) return pathname;

  return pathname.concat(
    '?',
    filteredParams.map((param) => `${param.name}=${param.value}`).join('&')
  );
};
