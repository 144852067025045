exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AppHeader_app-bar__30x4F {\n  background-color: #395a75;\n  position: relative;\n  height: 4rem;\n}\n\n.AppHeader_toolbar__YT8qS {\n  padding-right: 24px;\n  min-height: 4rem;\n}\n\n.AppHeader_toolbar__YT8qS .AppHeader_logo__5ClM5 {\n    margin-left: -10px;\n    height: 40px;\n  }\n\n.AppHeader_toolbar__YT8qS .AppHeader_content-right__higrq {\n    margin-left: auto;\n    padding-right: 8px;\n  }\n\n.AppHeader_toolbar__YT8qS .AppHeader_content-right__higrq .AppHeader_icon__26uBM {\n      color: inherit;\n    }\n\n.AppHeader_toolbar__YT8qS .AppHeader_content-right__higrq > * {\n      margin: 0px 4px;\n    }\n", ""]);

// Exports
exports.locals = {
	"app-bar": "AppHeader_app-bar__30x4F",
	"appBar": "AppHeader_app-bar__30x4F",
	"toolbar": "AppHeader_toolbar__YT8qS",
	"logo": "AppHeader_logo__5ClM5",
	"content-right": "AppHeader_content-right__higrq",
	"contentRight": "AppHeader_content-right__higrq",
	"icon": "AppHeader_icon__26uBM"
};