import {
  LOGIN_SUCCESS,
  LOGIN_PENDING,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
} from '../../constants/actions';

const initialState = {
  isAuthenticated: false,
  error: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        isAuthenticated: true,
        error: null,
        isLoading: false,
      };
    case LOGIN_FAIL:
      return {
        isAuthenticated: false,
        error: action.payload,
        isLoading: false,
      };
    case LOGIN_PENDING:
      return {
        isAuthenticated: false,
        error: null,
        isLoading: true,
      };
    case LOGOUT_FAIL:
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
}
