import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingProgress = ({ message, size }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    }}
  >
    <div style={{ margin: 'auto' }}>
      <CircularProgress size={size || 40} />
      {message}
    </div>
  </div>
);

export default LoadingProgress;
