export const rogueTradingRuleGroup = {
  name: 'Rogue trading',
  id: 'ROGUE_TRADING',
};
export const antifraudRuleGroup = {
  name: 'Antifraud',
  id: 'ANTIFRAUD',
};
export const marketAbuseRuleGroup = {
  name: 'Market abuse',
  id: 'MARKET_ABUSE',
};
export const unusualActivityRuleGroup = {
  name: 'Unusual activity',
  id: 'UNUSUAL_ACTIVITY',
};
export const effectivenessRuleGroup = {
  name: 'Effectiveness',
  id: 'EFFECTIVENESS',
};
export const customRuleGroup = {
  name: 'Custom rules',
  id: 'CUSTOM',
};
export const signalsRuleGroup = {
  name: 'External system signals',
  id: 'SIGNAL',
};
export const signalsManualRuleGroup = {
  name: 'Signal rules',
  id: 'SIGNAL_MANUAL',
};

export const ruleGroups = [
  antifraudRuleGroup,
  marketAbuseRuleGroup,
  rogueTradingRuleGroup,
  unusualActivityRuleGroup,
  effectivenessRuleGroup,
  customRuleGroup,
  signalsRuleGroup,
  signalsManualRuleGroup,
];

export const ruleGroupsObj = ruleGroups.reduce((obj, type) => {
  obj[type.id] = type;
  return obj;
}, {});
