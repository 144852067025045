import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from 'react-apollo';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dateFnsUtils from '@date-io/date-fns';
import { StylesProvider } from '@material-ui/styles';
import { ToastContainer, Flip } from 'react-toastify';
import { graphql } from '@tafs/services/api';
import { LicenseManager } from 'ag-grid-enterprise';
import { ModuleRegistry } from 'ag-grid-community';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
// enable that in prod build later
/* eslint-disable-next-line */
import { I18nextProvider } from 'react-i18next';

import i18n from '@tafs/i18n';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'highcharts/css/stocktools/gui.css';
import 'highcharts/css/annotations/popup.css';
import './scss/style.css';
import history from './history';
import App from './App';
import store from './store';
import 'react-toastify/dist/ReactToastify.min.css';
import 'typeface-roboto';

LicenseManager.setLicenseKey(
  'TAFS_Integrated_Solutions_Inc__TAFS_1Devs21_January_2020__MTU3OTU2NDgwMDAwMA==31edf512621f4b63c901c58560d56429'
);

ModuleRegistry.register(ClipboardModule);

const theme = createTheme({
  palette: {
    primary: { main: '#395a75' },
    secondary: { main: '#4c7c70' },
  },
  typography: {
    useNextVariants: true,
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={dateFnsUtils}>
          <StylesProvider injectFirst>
            <ToastContainer
              autoClose={6000}
              transition={Flip}
              limit={3}
              hideProgressBar
              position="bottom-right"
              theme="light"
            />
            <ApolloProvider client={graphql}>
              <I18nextProvider i18n={i18n}>
                <App />
              </I18nextProvider>
            </ApolloProvider>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
