import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import styles from './index.module.css';

export const MAX_CELL_WIDTH = 250;
const MAX_TEXT_LENGTH = 25;

const Truncatable = ({ value }) => {
  const [fullComment, setFullComment] = useState({});

  if (!value) return '';
  if (value?.length < MAX_TEXT_LENGTH) return value;
  else {
    return (
      <>
        <span
          className={styles.cellContent}
          onClick={(e) =>
            setFullComment({ value: value, anchorEl: e.currentTarget })
          }
        >
          {value}
        </span>
        <Popover
          open={!!fullComment?.anchorEl}
          anchorEl={fullComment?.anchorEl}
          onClose={() => setFullComment({...fullComment, anchorEl: null})}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography className={styles.popoverContent}>
            {(fullComment?.value || '').replaceAll(',', ', ')}
          </Typography>
        </Popover>
      </>
    );
  }
};

export default Truncatable;
