exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AppLayout_root__t4ZBa {\n  display: flex;\n  flex-direction: column;\n}\n\n  .AppLayout_root__t4ZBa .AppLayout_app-content__1Wrsi {\n    display: flex;\n    width: 100%;\n  }\n\n  .AppLayout_root__t4ZBa .AppLayout_app-content__1Wrsi .AppLayout_side-menu__3JXMC {\n      position: relative;\n    }\n\n  .AppLayout_root__t4ZBa .AppLayout_app-content__1Wrsi .AppLayout_scroll-container__vIhpX {\n      width: 100%;\n      height: calc(100vh - 4rem);\n    }\n\n  .AppLayout_root__t4ZBa .AppLayout_app-content__1Wrsi .AppLayout_scroll-container__vIhpX .AppLayout_page-content__1nZHk {\n        padding: 1rem;\n        height: 100%;\n      }\n", ""]);

// Exports
exports.locals = {
	"root": "AppLayout_root__t4ZBa",
	"app-content": "AppLayout_app-content__1Wrsi",
	"appContent": "AppLayout_app-content__1Wrsi",
	"side-menu": "AppLayout_side-menu__3JXMC",
	"sideMenu": "AppLayout_side-menu__3JXMC",
	"scroll-container": "AppLayout_scroll-container__vIhpX",
	"scrollContainer": "AppLayout_scroll-container__vIhpX",
	"page-content": "AppLayout_page-content__1nZHk",
	"pageContent": "AppLayout_page-content__1nZHk"
};