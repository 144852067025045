import React from 'react';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { store, urlParam } from './utils';

const StatefulLink = ({
  children,
  pathname,
  storedObj,
  forceNewTab,
}) => {
  const recordId = uuidv4();

  return (
    <div onMouseDown={() => store(recordId, storedObj)}>
      <a
        target={forceNewTab && '_blank'}
        rel="noopener noreferrer"
        href={`${pathname}?${urlParam}=${recordId}`}
      >
        {children}
      </a>
    </div>
  );
};

StatefulLink.propTypes = {
  pathname: PropTypes.string.isRequired,
  storedObj: PropTypes.object.isRequired,
  forceNewTab: PropTypes.bool,
};

export default StatefulLink;
