export default (request, pagSize) => ({
  getRows: (params) => {
    const currentPage =
      params.parentNode.gridOptionsWrapper.gridApi.paginationProxy.currentPage;
    let sort = params.request.sortModel[0];
    if (sort) sort = Object.values(sort).join(',');

    request(currentPage, pagSize, sort)
      .then((data) => params.successCallback(data.content, data.total))
      .catch((err) => {
        console.error(err);
        params.failCallback();
      });
  },
});
