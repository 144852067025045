import { INVESTIGATION, SANDBOX, SCHEDULE, IRRESPECTIVE } from './appModes';

/**
 * keys usage:
 * * id         - workspaceObj key
 * * permission - encoded on backend in Permissions class, recieved on auth
 * * path       - will be shown in url
 * * enum       - encoded on backend in WorkSpaceEnum
 * * rest       - encoded as a part of some endpoint URLs, if any
 */
export const workspaceObj = {
  ADMIN: { id: 'ADMIN', permission: 'admin', path: 'admin', enum: 'ADMIN' },
  SECURITY: {
    id: 'SECURITY',
    permission: 'admin_ib',
    path: 'security',
    enum: 'ADMIN_IB',
  },
  ANALYST: {
    id: 'ANALYST',
    permission: 'analytic_ib',
    path: 'analyst',
    enum: 'ANALYTIC_IB',
    rest: 'analytic-ib',
  },
  COMPLIANCE: {
    id: 'COMPLIANCE',
    permission: 'analytic_compliance',
    path: 'compliance',
    enum: 'ANALYTIC_COMPLIANCE',
    rest: 'analytic-compliance',
  },
};

export const getAllowedWorkspaces = (permissions) =>
  Object.values(workspaceObj).filter((ws) => permissions[ws.permission]);

export const getAppModeAndWorkspace = (url, permissions) => {
  const [, wsFromUrl, modeFromUrl] = url.split('/');

  const allowedWorkspacePaths = getAllowedWorkspaces(permissions).map(
    (ws) => ws.path
  );
  const wsAllowed = allowedWorkspacePaths.includes(wsFromUrl)
    ? wsFromUrl
    : allowedWorkspacePaths[0];
  const wsKey = Object.keys(workspaceObj).find(
    (key) => workspaceObj[key].path === wsAllowed
  );

  const modeUppercased = modeFromUrl && modeFromUrl.toUpperCase();
  const mode = [INVESTIGATION, SANDBOX, SCHEDULE].includes(modeUppercased)
    ? modeUppercased
    : IRRESPECTIVE;

  return { mode, workspace: workspaceObj[wsKey] };
};

export const getWorkspacePath = (workspace, path) =>
  `/${workspace.path}${path}`;

export const getAvailableRouteInConfig = (routeConfig, workspacePath) =>
  Object.values(routeConfig).find(
    (route) => route.path.split('/')[1] === workspacePath
  )?.path;

export const getWorkspaceByEnum = (wsEnum) =>
  Object.values(workspaceObj).find((workspace) => workspace.enum === wsEnum);

export const getWorkspaceByPermission = (wsPermission) =>
  Object.values(workspaceObj).find(
    (workspace) => workspace.permission === wsPermission
  );

export const getSignalsWS = (ws) => {
  return ws.id === workspaceObj.ANALYST.id
    ? { moex: 'signalIb', mm: 'signalMarketMakerReportIb' }
    : { moex: 'signalCompliance', mm: 'signalMarketMakerReportCompliance' };
};
