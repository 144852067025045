import {
  SORT_RULES_BY_RULE_NAME,
  SORT_RULES_BY_RULE_CODE,
  SET_REPORT_DATE,
  SET_RULES_DETAILS,
  SET_RULES_USER,
  SET_SELECTED_RULE_GROUP,
} from '@tafs/constants/actions';
import { sortBy } from '@tafs/constants/reports/rules';
import {
  INVESTIGATION,
  SCHEDULE,
  IRRESPECTIVE,
} from '@tafs/constants/appModes';

const initialState = {
  selectedSort: sortBy.ruleNameAsc.id,
  reportDate: new Date(),
  detailsObj: {},
  selectedUser: null,
  selectedRuleGroup: {
    [INVESTIGATION]: '',
    [SCHEDULE]: '',
    [IRRESPECTIVE]: '',
  },
};

export default function (state = initialState, action) {
  const { selectedSort } = state;

  switch (action.type) {
    case SORT_RULES_BY_RULE_NAME:
      return {
        ...state,
        selectedSort:
          selectedSort === sortBy.ruleNameAsc.id
            ? sortBy.ruleNameDesc.id
            : sortBy.ruleNameAsc.id,
      };
    case SORT_RULES_BY_RULE_CODE:
      return {
        ...state,
        selectedSort:
          selectedSort === sortBy.ruleCodeAsc.id
            ? sortBy.ruleCodeDesc.id
            : sortBy.ruleCodeAsc.id,
      };
    case SET_REPORT_DATE:
      return {
        ...state,
        reportDate: action.payload,
      };
    case SET_RULES_DETAILS:
      return {
        ...state,
        detailsObj: action.payload,
      };
    case SET_RULES_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case SET_SELECTED_RULE_GROUP:
      return {
        ...state,
        selectedRuleGroup: {
          ...state.selectedRuleGroup,
          [action.payload.mode]: action.payload.selectedRuleGroup,
        },
      };
    default:
      return state;
  }
}
