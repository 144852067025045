import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import styles from './index.module.css';

const IncompleteTickers = ({ tickers }) => {
  const { t } = useTranslation();
  const [anchor, setAnchor] = useState(null);

  return (
    <>
      {t('Tickers with missing Currency Contract field')}:
      <span className={styles.link} onClick={(e) => setAnchor(e.currentTarget)}>
        {tickers.length}
      </span>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={styles.popover}>
          {tickers.map((el, index, array) => (
            <React.Fragment key={index + el.ticker}>
              {`${el.ticker}(${el.venue})`}
              {index !== array.length - 1 && ', '}
            </React.Fragment>
          ))}
        </Typography>
      </Popover>
    </>
  );
};

export default IncompleteTickers;
