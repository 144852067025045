import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// TODO: move translations to backend
// import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import config from './i18nextConfig';

i18next.use(LanguageDetector).use(initReactI18next).init(config);

// TODO: move translations to backend
// i18next.use(LanguageDetector).use(initReactI18next).use(Backend).init(config);

export default i18next;
