export const assetClass = {
  name: 'Asset class',
  id: 'assetClass',
};
export const dealtype = {
  name: 'Deal type',
  id: 'dealtype',
};

export const business = {
  name: 'Business',
  id: 'business',
};

export const businessLine = {
  name: 'Business line',
  id: 'businessLine',
};

export const contractcode = {
  name: 'Financial instrument',
  id: 'ticker',
};

export const counterparty = {
  name: 'Counterparty',
  id: 'counterparty',
};

export const client = {
  name: 'Client',
  id: 'client',
};

export const dealmode = {
  name: 'Deal mode',
  id: 'dealmode',
};

export const desk = {
  name: 'Desk',
  id: 'desk',
};

export const entity = {
  name: 'Entity',
  id: 'entity',
};

export const location = {
  name: 'Location',
  id: 'location',
};

export const portfolio = {
  name: 'Portfolio',
  id: 'portfolio',
};

export const salesTeam = {
  name: 'Sales team',
  id: 'salesTeam',
};

export const backOffice = {
  name: 'Support staff BO',
  id: 'backOffice',
};

export const middleOffice = {
  name: 'Support staff MO',
  id: 'middleOffice',
};

export const trader = {
  name: 'Trader',
  id: 'trader',
};

export const userId = {
  name: 'User',
  id: 'user',
};

export const venue = {
  name: 'Venue',
  id: 'venue',
};

export const ruleId = {
  name: 'Rule ID',
  id: 'ruleId',
};

export const ruleGroup = {
  name: 'Rule group',
  id: 'ruleGroup',
};

export const reportFields = [
  assetClass,
  dealtype,
  business,
  businessLine,
  contractcode,
  counterparty,
  client,
  dealmode,
  desk,
  entity,
  location,
  portfolio,
  salesTeam,
  backOffice,
  middleOffice,
  trader,
  userId,
  venue,
  ruleId,
  ruleGroup,
];

export const getGetFieldById = (id) => {
  const lowerId = id.toLowerCase();
  return reportFields.find((field) => field.id.toLowerCase() === lowerId);
};
