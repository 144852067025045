import {
  SET_CHART_AA_RULE,
  SET_CHART_CMP_TICKER,
  SET_CHART_CMP_DATE_FROM,
  SET_CHART_CMP_DATE_TO,
  SET_CHART_CMP_CLIENT,
  SET_CHART_CMP_RULE,
  SET_CHART_CMP_DATA,
} from '@tafs/constants/actions';

const initialState = {
  aaRule: 'D01100v1',
  cmpDateFrom: '',
  cmpDateTo: '',
  cmpTicker: '',
  cmpClient: '',
  cmpRule: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CHART_AA_RULE:
      return {
        ...state,
        aaRule: action.payload,
      };
    case SET_CHART_CMP_TICKER:
      return {
        ...state,
        cmpTicker: action.payload,
      };
    case SET_CHART_CMP_DATE_FROM:
      return {
        ...state,
        cmpDateFrom: action.payload,
      };
    case SET_CHART_CMP_DATE_TO:
      return {
        ...state,
        cmpDateTo: action.payload,
      };
    case SET_CHART_CMP_CLIENT:
      return {
        ...state,
        cmpClient: action.payload,
      };
    case SET_CHART_CMP_RULE:
      return {
        ...state,
        cmpRule: action.payload,
      };
    case SET_CHART_CMP_DATA:
      return {
        ...state,
        cmpDateFrom: action.payload.dateFrom,
        cmpDateTo: action.payload.dateTo,
        cmpTicker: action.payload.ticker,
        cmpClient: action.payload.client,
        cmpRule: action.payload.rule,
      };
    default:
      return state;
  }
}
