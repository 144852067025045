import { add, format, max, min, sub } from 'date-fns';

import {
  SET_REPORT_TYPE,
  SET_SLICE,
  SET_REPORT_FILTERS,
  SET_REPORT_PRIORITIES,
  SET_REPORT_DATES,
  SET_REPORT_START_DATE,
  SET_REPORT_END_DATE,
  SET_REPORT_ALGORITHMS,
  SET_REPORT_DISPLAY_FINANCIAL_RESULT,
  SET_REPORT_FINANCIAL_RESULT_CURRENCY,
  SET_REPORT_FINANCIAL_RESULT_ABS,
  TOGGLE_REPORT_EMPTY_COLUMNS_FILTERED,
  SET_RULE_GROUPS_BY_WORKSPACE,
} from '@tafs/constants/actions';
import {
  enterpriseSummaryReportType,
  assetClassSlice,
} from '@tafs/constants/reports';
import { INVESTIGATION, SCHEDULE } from '@tafs/constants/appModes';
import { workspaceObj } from '@tafs/constants/workspaces';
import { currency } from '@tafs/constants/reports/fineTuningEnums';
import { MAX_DISTANCE_MONTHS } from '@tafs/constants/reports/filters';

const initialState = {
  reportType: enterpriseSummaryReportType.id,
  sliceBy: assetClassSlice.id,
  filterBy: [],
  priorities: [0],
  startDate: format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
  algorithms: [],
};

const initialStateByMode = {
  [INVESTIGATION]: { ...initialState },
  [SCHEDULE]: { ...initialState },
};

const initialStateByWorkspace = {
  [workspaceObj.ANALYST.enum]: { ...initialStateByMode },
  [workspaceObj.COMPLIANCE.enum]: { ...initialStateByMode },
  isFinancialResultDisplayed: false,
  financialResultCurrency: currency.values[0],
  financialResultAbs: true,
  emptyColumnsFiltered: false,
  ruleGroupsByWorkSpace: {},
};

export default function (state = initialStateByWorkspace, action) {
  switch (action.type) {
    case SET_REPORT_TYPE:
      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            reportType: action.payload,
          },
        },
      };
    case SET_REPORT_PRIORITIES:
      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            priorities:
              action.payload.length > 0
                ? action.payload
                : initialState.priorities,
          },
        },
      };
    case SET_SLICE:
      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            sliceBy: action.payload,
          },
        },
      };
    case SET_REPORT_FILTERS:
      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            filterBy: action.payload,
          },
        },
      };
    case SET_REPORT_DATES:
      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            startDate: action.payload.startDate,
            endDate: action.payload.endDate,
          },
        },
      };
    case SET_REPORT_START_DATE:
      const validEndDate = format(
        min([
          add(Date.parse(action.payload), {
            months: MAX_DISTANCE_MONTHS,
            days: -1,
          }),
          new Date(state?.[action.workspace]?.[action.mode]?.startDate),
        ]),
        'yyyy-MM-dd'
      );

      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            startDate: action.payload,
            endDate: validEndDate,
          },
        },
      };
    case SET_REPORT_END_DATE:
      const validStartDate = format(
        max([
          sub(Date.parse(action.payload), {
            months: MAX_DISTANCE_MONTHS,
            days: -1,
          }),
          new Date(state?.[action.workspace]?.[action.mode]?.startDate),
        ]),
        'yyyy-MM-dd'
      );
      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            endDate: action.payload,
            startDate: validStartDate,
          },
        },
      };
    case SET_REPORT_ALGORITHMS:
      return {
        ...state,
        [action.workspace]: {
          ...state[action.workspace],
          [action.mode]: {
            ...state[action.workspace][action.mode],
            algorithms: action.payload,
          },
        },
      };
    case SET_REPORT_DISPLAY_FINANCIAL_RESULT:
      return {
        ...state,
        isFinancialResultDisplayed: action.payload,
      };
    case SET_REPORT_FINANCIAL_RESULT_CURRENCY:
      return {
        ...state,
        financialResultCurrency: action.payload,
      };
    case SET_REPORT_FINANCIAL_RESULT_ABS:
      return {
        ...state,
        financialResultAbs: action.payload,
      };
    case TOGGLE_REPORT_EMPTY_COLUMNS_FILTERED:
      return {
        ...state,
        emptyColumnsFiltered: !state.emptyColumnsFiltered,
      };
    case SET_RULE_GROUPS_BY_WORKSPACE:
      return {
        ...state,
        ruleGroupsByWorkSpace: action.payload,
      };
    default:
      return state;
  }
}
