import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import Status from '.';
import { NS } from '@tafs/i18n/i18nextConfig';

const NoData = () => {
  const { t } = useTranslation(NS.CONSTANTS);

  return <Status message={t('No data')} />;
};

NoData.stringify = () =>
  `<span style="border-radius: 6px;
              padding: 12px 24px;
              border: 1px solid #b7b0b0;
              box-shadow: 4px 4px 6px #b7b0b0;"
>
  ${i18n.t('No data')}
</span>`;

export default NoData;
