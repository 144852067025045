import { SET_APP_MODE_AND_WORKSPACE } from '@tafs/constants/actions';
import { IRRESPECTIVE } from '@tafs/constants/appModes';

const initialState = {
  mode: IRRESPECTIVE,
  workspace: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_APP_MODE_AND_WORKSPACE:
      const { workspace, mode } = action.payload;
      return {
        ...state,
        workspace,
        mode,
      };
    default:
      return state;
  }
}
