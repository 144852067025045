import {
  SET_SIDE_MENU_CONFIG,
  SET_SIDE_MENU_SCROLL,
} from '@tafs/constants/actions';

const initialState = {
  gpoupsOpened: {},
  scrollPosition: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SIDE_MENU_CONFIG:
      return {
        ...state,
        gpoupsOpened: action.payload,
      };
    case SET_SIDE_MENU_SCROLL:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    default:
      return state;
  }
}
