import React from 'react';
import { connect } from 'react-redux';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const Profile = ({ username }) => (
  <span>
    <PersonOutlineIcon /> {username}
  </span>
);

const mapStateToProps = (state) => ({
  username: state.user.username,
});

export default connect(mapStateToProps)(Profile);
