import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './index.module.css';
import { NS } from '@tafs/i18n/i18nextConfig';

const Page403 = () => {
  const { t } = useTranslation(NS.NAVIGATION);
  return (
    <div className={styles.root}>
      <h1>{t('Access forbidden')}</h1>
    </div>
  );
};

export default Page403;
