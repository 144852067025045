import {
  SET_STATUS_READ_IDS,
  SET_STATUS_NEW_IDS,
  TOGGLE_STATUS_AUTO_UPDATE,
} from '@tafs/constants/actions';

const initialState = {
  readIds: [],
  unreadCount: 0,
  isAutoUpdate: false,
};

const getTaskIdAndUpdateTime = (task) => `${task.id}_${task.updateAt}`;

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STATUS_READ_IDS:
      return {
        ...state,
        readIds: action.payload.map(getTaskIdAndUpdateTime),
        unreadCount: 0,
      };
    case SET_STATUS_NEW_IDS:
      return {
        ...state,
        unreadCount: action.payload.filter(
          (task) => !state.readIds.includes(getTaskIdAndUpdateTime(task))
        ).length,
      };
    case TOGGLE_STATUS_AUTO_UPDATE:
      return {
        ...state,
        isAutoUpdate: !state.isAutoUpdate,
      };
    default:
      return state;
  }
}
