import React from 'react';
import { useSelector } from 'react-redux';

import Page403 from '@tafs/components/pages/Page403';
import { isPartOf } from '@tafs/constants/permissions';

const withAuthorization = (WrappedComponent, workspace, allowed) => (props) => {
  const permissions = useSelector((state) => state.user.permissions);

  if (isPartOf(`${workspace.permission}.${allowed}`, permissions))
    return <WrappedComponent {...props} />;

  return <Page403 />;
};

export default withAuthorization;
