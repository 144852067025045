import {
  LOCATION_CHANGE,
  SET_SIDE_MENU_CONFIG,
  SET_APP_MODE_AND_WORKSPACE,
} from '@tafs/constants/actions';
import { createStateSyncMiddleware } from 'redux-state-sync';

const stateSyncMiddlware = () =>
  createStateSyncMiddleware({
    blacklist: [
      LOCATION_CHANGE,
      SET_SIDE_MENU_CONFIG,
      SET_APP_MODE_AND_WORKSPACE,
    ],
  });

export default stateSyncMiddlware;
