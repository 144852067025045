import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './index.module.css';
import { partial } from '@tafs/utils';
import { getTaskHistory } from '@tafs/services/api';
import PageableTable from '@tafs/components/common/PageableTable';
import { NS } from '@tafs/i18n/i18nextConfig';
import { TaskHistoryDetails } from './TaskHistoryDetails';

const TaskHistoryDialog = ({ onClose, id }) => {
  const { t } = useTranslation(NS.CONSTANTS);
  const getSpecificTaskHistory = useMemo(
    () => partial(getTaskHistory, id),
    [id]
  );
  const columnDefs = useMemo(
    () => [
      {
        field: 'updateAt',
        headerName: 'Updated at',
        sortable: false,
      },
      {
        field: 'jobName',
        headerName: 'Task',
        sortable: false,
        cellRendererFramework: (params) => t(params.value),
      },
      {
        field: 'jobStatus',
        headerName: 'Status',
        sortable: false,
        cellRendererFramework: (params) => t(params.value),
      },
      {
        field: 'username',
        headerName: 'Username',
        sortable: false,
      },
      {
        field: 'nextRunAt',
        headerName: 'Next launch time',
        sortable: false,
      },
      {
        field: 'currentJson',
        headerName: 'Details',
        sortable: false,
        cellRendererFramework: ({ value }) => (
          <TaskHistoryDetails value={value} />
        ),
      },
    ],
    [t]
  );

  return (
    <Dialog
      open={!!id}
      onClose={onClose}
      fullWidth
      PaperProps={{ className: styles.paper }}
    >
      <DialogTitle>{t('Task history')}</DialogTitle>
      <DialogContent className={styles.content}>
        <PageableTable
          request={getSpecificTaskHistory}
          stateKey="task-history"
          columnDefs={columnDefs}
          containerClassName={styles.container}
        />
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default TaskHistoryDialog;
