import {
  SET_STATUS_READ_IDS,
  SET_STATUS_NEW_IDS,
  TOGGLE_STATUS_AUTO_UPDATE,
} from '@tafs/constants/actions';

export const setStatusReadIds = (statusIds) => ({
  type: SET_STATUS_READ_IDS,
  payload: statusIds,
});

export const setStatusNewIds = (tasks) => ({
  type: SET_STATUS_NEW_IDS,
  payload: tasks,
});

export const toggleStatusAutoUpdate = () => ({
  type: TOGGLE_STATUS_AUTO_UPDATE,
});
