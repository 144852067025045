export const asset_class = {
  type: 'asset_class[]',
};

export const dealmode = {
  type: 'dealmode[]',
};

export const dealtype = {
  type: 'dealtype[]',
};

export const investor_type = {
  type: 'investor_type[]',
};

export const liquidity = {
  type: 'liquidity[]',
};

export const venue = {
  type: 'venue[]',
};

export const aggregation_period = {
  type: 'aggregation_period',
};

export const aggregation_period_alt = {
  type: 'aggregation_period_alt',
};

export const boolean_with_global = {
  type: 'boolean_with_global',
};

export const days_or_sessions = {
  type: 'days_or_sessions',
};

export const currency = {
  type: 'currency',
  values: ['rub', 'usd', 'eur', 'cny'],
  rub: {
    id: 'rub',
    symbol: '₽',
    field: 'financialResultRub',
    fieldAbs: 'financialResultAbsRub',
  },
  usd: {
    id: 'usd',
    symbol: '$',
    field: 'financialResultUsd',
    fieldAbs: 'financialResultAbsUsd',
  },
  eur: {
    id: 'eur',
    symbol: '€',
    field: 'financialResultEur',
    fieldAbs: 'financialResultAbsEur',
  },
  cny: {
    id: 'cny',
    symbol: '¥',
    field: 'financialResultCny',
    fieldAbs: 'financialResultAbsCny',
  },
};

export const settlecode = {
  type: 'settlecode',
  obj: {
    0: 'settlecode.ignore',
    1: 'settlecode.similarity',
    2: 'settlecode.match',
  },
};

export const check_financial_result = {
  type: 'check_financial_result',
  obj: {
    0: 'check_financial_result.ignore',
    1: 'check_financial_result.positive',
    2: 'check_financial_result.negative',
    3: 'check_financial_result.both',
  },
};

export const enums = [
  asset_class,
  dealmode,
  dealtype,
  investor_type,
  liquidity,
  venue,
  aggregation_period,
  aggregation_period_alt,
  boolean_with_global,
  check_financial_result,
  days_or_sessions,
  currency,
  settlecode,
];
