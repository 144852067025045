import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import user from './user';
import reports from './reports';
import sideMenu from './sideMenu';
import charts from './charts';
import app from './app';
import history from '@tafs/history';
import isLoaded from './isLoaded';
import status from './status';
import rules from './rules';
import auth from './auth';
import overlapReports from './overlapReports';
import oneClickAudit from './oneClickAudit';
import incidentCardInputs from './incidentCardInputs';
import clientCardInputs from './clientCardInputs';

export default combineReducers({
  router: connectRouter(history),
  auth,
  user,
  reports,
  sideMenu,
  charts,
  app,
  isLoaded,
  status,
  rules,
  overlapReports,
  oneClickAudit,
  incidentCardInputs,
  clientCardInputs,
});
