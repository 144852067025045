import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { NS } from '@tafs/i18n/i18nextConfig';
import { getTaskStatus } from '@tafs/services/api';
import {
  setStatusReadIds,
  setStatusNewIds,
  toggleStatusAutoUpdate,
} from '@tafs/acs/status';
import { notifyService } from '@tafs/services/notifications';
import styles from './index.module.css';
import TaskRow from './TaskRow';
import TaskHistoryDialog from './TaskHistoryDialog';

const STATUS_UPDATE_INTERVAL_MS = 10000;
const COUNT_TASKS_PER_PAGE = 20;

const TaskStatus = () => {
  const [statusPopover, setStatusPopover] = useState(null);
  const [tasks, setTasks] = useState({});
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation(NS.CONSTANTS);

  const dispatch = useDispatch();
  const { unreadCount, isAutoUpdate, workspaceEnum } = useSelector((state) => {
    const { unreadCount, isAutoUpdate } = state.status;
    return {
      unreadCount,
      isAutoUpdate,
      workspaceEnum: state.app.workspace?.enum,
    };
  });

  const getTaskStatuses = useCallback(
    (page = 0) =>
      getTaskStatus(workspaceEnum, COUNT_TASKS_PER_PAGE, page)
        .then(({ content, total }) => {
          setTasks((prevTasks) => ({ ...prevTasks, [page]: content }));
          setTotal(total);
          if (page === undefined) dispatch(setStatusNewIds(content));
        })
        .catch(({ message }) => notifyService.error(message)),
    [dispatch, workspaceEnum]
  );

  useEffect(() => {
    getTaskStatuses();
  }, [getTaskStatuses]);

  useEffect(() => {
    let timerID;

    if (isAutoUpdate) {
      getTaskStatuses();
      timerID = setInterval(() => getTaskStatuses(), STATUS_UPDATE_INTERVAL_MS);
    }

    return () => {
      clearInterval(timerID);
    };
  }, [getTaskStatuses, isAutoUpdate]);

  const handleSwitch = useCallback(
    () => dispatch(toggleStatusAutoUpdate()),
    [dispatch]
  );

  const handleOpen = (event) => {
    setPage(0);
    setStatusPopover(event.currentTarget);
    getTaskStatuses();
  };

  const handleClose = () => {
    if (page === 0) dispatch(setStatusReadIds(tasks[0]));
    setStatusPopover(null);
  };

  const handleHistoryOpen = useCallback((id) => {
    setIsHistoryOpen(id);
  }, []);

  const handleHistoryClose = useCallback(() => {
    setIsHistoryOpen(false);
  }, []);

  const handleNavigate = async (e) => {
    const pageNum = e.currentTarget.value === 'next' ? page + 1 : page - 1;
    if (page === 0) dispatch(setStatusReadIds(tasks[0]));
    await getTaskStatuses(pageNum);
    setPage(pageNum);
  };

  return (
    <span>
      <Tooltip title={t('Tasks status')} enterDelay={300}>
        <IconButton color="inherit" onClick={tasks[0] && handleOpen}>
          <Badge badgeContent={unreadCount} color="secondary">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        id="status-popover"
        anchorEl={statusPopover}
        open={Boolean(statusPopover)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className={styles.controls}>
          <FormControlLabel
            className={styles.autoUpdate}
            control={
              <Switch
                color="primary"
                size="small"
                checked={isAutoUpdate}
                onChange={handleSwitch}
              />
            }
            label={t('Update automatically')}
          />
          <div className={styles.pagination}>
            <IconButton
              value="prev"
              size="small"
              onClick={handleNavigate}
              disabled={page === 0}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <div className={styles.pageNumber}>{page + 1}</div>
            <IconButton
              value="next"
              size="small"
              onClick={handleNavigate}
              disabled={page + 1 >= Math.ceil(total / COUNT_TASKS_PER_PAGE)}
            >
              <NavigateNextIcon />
            </IconButton>
          </div>
        </div>
        {tasks[page] && tasks[page].length > 0 ? (
          <Table size="small" className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t('Time')}</TableCell>
                <TableCell>{t('Task')}</TableCell>
                <TableCell colSpan={3}>{t('Status')}</TableCell>
                <TableCell className={styles.a}></TableCell>
                <TableCell>{t('User')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks[page] &&
                tasks[page].map((record) => (
                  <TaskRow
                    key={record.id}
                    record={record}
                    handleHistoryOpen={handleHistoryOpen}
                    isFirstPage={page === 0}
                  />
                ))}
            </TableBody>
          </Table>
        ) : (
          <Box className={styles.noData}>{t('No data')}</Box>
        )}
      </Popover>
      <TaskHistoryDialog onClose={handleHistoryClose} id={isHistoryOpen} />
    </span>
  );
};

export default TaskStatus;
