import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppHeader from './AppHeader';
import SideMenu from './SideMenu';
import Page404 from '@tafs/components/pages/Page404';
import ShadowScrollbars from '@tafs/components/common/ShadowScrollbars';
import { routeConfig } from '@tafs/routeConfig';
import { setAppModeAndWorkspace } from '@tafs/acs/sideMenu';
import {
  getAppModeAndWorkspace,
  getAvailableRouteInConfig,
} from '@tafs/constants/workspaces';
import styles from './index.module.css';

const AppLayout = ({ location: { pathname } }) => {
  const permissions = useSelector((state) => state.user.permissions);
  const dispatch = useDispatch();

  const { mode, workspace } = getAppModeAndWorkspace(pathname, permissions);
  dispatch(setAppModeAndWorkspace({ mode, workspace }));

  return (
    <div className={styles.root}>
      <CssBaseline />
      <AppHeader />
      <div className={styles.appContent}>
        <SideMenu className={styles.sideMenu} />
        <ShadowScrollbars className={styles.scrollContainer}>
          <main className={styles.pageContent}>
            <Switch>
              {routeConfig.map(({ component: Component, ...route }) =>
                Component ? (
                  <Route
                    key={`${route.path}_${route.name}`}
                    render={(props) => <Component {...props} />}
                    {...route}
                  />
                ) : (
                  !!route.getRedirectRoute && (
                    <Route
                      key={`${route.path}_${route.name}`}
                      exact={route.exact}
                      render={() => (
                        <Redirect to={route.getRedirectRoute(permissions)} />
                      )}
                      {...route}
                    />
                  )
                )
              )}
              <Redirect
                exact
                from="/"
                to={getAvailableRouteInConfig(routeConfig, workspace.path)}
              />
              <Route component={Page404} />
            </Switch>
          </main>
        </ShadowScrollbars>
      </div>
    </div>
  );
};

export default AppLayout;
