import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import LanguageIcon from '@material-ui/icons/Language';
import { withTranslation } from 'react-i18next';

import { NS } from '@tafs/i18n/i18nextConfig';
import styles from './index.module.css';

class Language extends PureComponent {
  state = {
    languageMenu: null,
  };

  userLanguage = this.props.i18n.language;

  handleLanguageIconClick = (event) => {
    this.setState({ languageMenu: event.currentTarget });
  };

  handleLanguageMenuClose = () => {
    this.setState({ languageMenu: null });
  };

  handleLanguageMenuItemClick = (lang) => {
    const { i18n } = this.props;

    if (lang !== this.userLanguage) {
      i18n.changeLanguage(lang);
      window.location.reload();
    }
    this.handleLanguageMenuClose();
  };

  render() {
    const { languageMenu } = this.state;
    const { t } = this.props;

    return (
      <>
        <Tooltip title={t('Change language')} enterDelay={300}>
          <IconButton
            color="inherit"
            aria-owns={languageMenu ? 'language-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleLanguageIconClick}
          >
            <LanguageIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="language-menu"
          anchorEl={languageMenu}
          open={Boolean(languageMenu)}
          onClose={this.handleLanguageMenuClose}
        >
          <MenuItem
            className={styles.menuItem}
            selected={this.userLanguage === 'en'}
            onClick={() => this.handleLanguageMenuItemClick('en')}
          >
            English
          </MenuItem>
          <MenuItem
            className={styles.menuItem}
            selected={this.userLanguage === 'ru'}
            onClick={() => this.handleLanguageMenuItemClick('ru')}
          >
            Русский
          </MenuItem>
          <MenuItem
            className={styles.menuItem}
            selected={this.userLanguage === 'zh'}
            onClick={() => this.handleLanguageMenuItemClick('zh')}
          >
            中文
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default withTranslation(NS.CONSTANTS)(Language);
