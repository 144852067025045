exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".StatusDetails_table__26K9Q {\n  max-width: 80vw;\n}\n\n  .StatusDetails_table__26K9Q .StatusDetails_header__2gBUl {\n    font-weight: bold;\n  }\n\n  .StatusDetails_table__26K9Q .StatusDetails_error-message__7V_pp {\n    background-color: #ffe7e6;\n  }\n\n  .StatusDetails_table__26K9Q .StatusDetails_warning-message__qVft6 {\n    background-color: #fff7e6;\n  }\n\n.StatusDetails_warning__1rPu0 {\n  padding-left: 32px;\n  text-align: left;\n}\n\n.StatusDetails_noBorder__2CUF- {\n  border: none;\n}\n\n.StatusDetails_error-comment__20H1u {\n  margin-bottom: 4px;\n  color: #ea4435;\n}\n", ""]);

// Exports
exports.locals = {
	"table": "StatusDetails_table__26K9Q",
	"header": "StatusDetails_header__2gBUl",
	"error-message": "StatusDetails_error-message__7V_pp",
	"errorMessage": "StatusDetails_error-message__7V_pp",
	"warning-message": "StatusDetails_warning-message__qVft6",
	"warningMessage": "StatusDetails_warning-message__qVft6",
	"warning": "StatusDetails_warning__1rPu0",
	"noBorder": "StatusDetails_noBorder__2CUF-",
	"error-comment": "StatusDetails_error-comment__20H1u",
	"errorComment": "StatusDetails_error-comment__20H1u"
};