import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import SecurityIcon from '@material-ui/icons/Security';
import Tooltip from '@material-ui/core/Tooltip';

import VersionDialog from './VersionDialog';

const AppVersion = () => {
  const [versionDialogOpen, setVersionDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('Build version')} enterDelay={300}>
        <IconButton color="inherit" onClick={() => setVersionDialogOpen(true)}>
          <SecurityIcon />
        </IconButton>
      </Tooltip>
      {versionDialogOpen && (
        <VersionDialog onClose={() => setVersionDialogOpen(false)} />
      )}
    </>
  );
};

export default AppVersion;
