import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
} from '@material-ui/core';

import { getAppVersion } from '@tafs/services/api';
import { notifyService } from '@tafs/services/notifications';
import LoadingProgress from '@tafs/components/common/LoadingProgress';
import styles from './index.module.css';

export default function VersionDialog({ onClose }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoaded(false);
    getAppVersion()
      .then((res) => setData(res))
      .catch((err) => notifyService.error(err.message))
      .finally(() => setIsLoaded(true));
  }, []);

  return (
    <Dialog onClose={onClose} open={true} maxWidth="xs" fullWidth>
      <DialogTitle>{t('Build version')}</DialogTitle>
      <DialogContent>
        {isLoaded ? (
          <TableContainer className={styles.container}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{`${t('Build version')}: `}</TableCell>
                  <TableCell>{data.version}</TableCell>
                </TableRow>
                {data.buildDate && (
                  <TableRow>
                    <TableCell>{`${t('Build date')}: `}</TableCell>
                    <TableCell>{data.buildDate}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <LoadingProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
