import {
  SET_GLOBAL_TICKERS_SCHEDULE_URL,
  SET_GLOBAL_CLIENTS_SCHEDULE_URL,
  SET_GLOBAL_MARKET_MAKER_SCHEDULE_URL,
  SET_GLOBAL_TICKERS_ANALYTIC_URL,
  SET_GLOBAL_CLIENTS_ANALYTIC_URL,
  SET_GLOBAL_MARKET_MAKER_ANALYTIC_URL,
} from './api';
import { SCHEDULE, INVESTIGATION } from '../constants/appModes';

export const clients = 'CLIENTS';
export const tickers = 'TICKERS';
export const isMarketMaker = 'IS_MARKET_MAKER';

const globalFilterUrls = {
  [clients]: {
    [SCHEDULE]: SET_GLOBAL_CLIENTS_SCHEDULE_URL,
    [INVESTIGATION]: SET_GLOBAL_CLIENTS_ANALYTIC_URL,
  },
  [tickers]: {
    [SCHEDULE]: SET_GLOBAL_TICKERS_SCHEDULE_URL,
    [INVESTIGATION]: SET_GLOBAL_TICKERS_ANALYTIC_URL,
  },
  [isMarketMaker]: {
    [SCHEDULE]: SET_GLOBAL_MARKET_MAKER_SCHEDULE_URL,
    [INVESTIGATION]: SET_GLOBAL_MARKET_MAKER_ANALYTIC_URL,
  },
};

export const getGlobalFilterUrl = (globalFilter, mode) =>
  globalFilterUrls?.[globalFilter]?.[mode];
