import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ComputerIcon from '@material-ui/icons/Computer';
import classNames from 'classnames';

import {
  getAllowedWorkspaces,
  getAvailableRouteInConfig,
} from '@tafs/constants/workspaces';
import { routeConfig } from '@tafs/routeConfig';
import styles from './index.module.css';

const WorkspaceSelect = ({ history, expanded }) => {
  const [visibleWorkspaces, workspace] = useSelector((state) => [
    getAllowedWorkspaces(state.user.permissions),
    state.app.workspace,
  ]);
  const [anchor, setAnchor] = useState(null);
  const { t } = useTranslation();

  const handleOpen = ({ currentTarget }) => {
    if (visibleWorkspaces.length > 1) setAnchor(currentTarget);
  };

  const handleClose = () => setAnchor(null);

  const handleWorkspaceChange = (e) => {
    const { nextWs } = e.currentTarget.dataset;

    if (nextWs !== workspace.path) {
      const route = getAvailableRouteInConfig(routeConfig, nextWs);
      history.push(route);
    }
    handleClose();
  };

  return (
    <div>
      <Button
        size="large"
        color="primary"
        fullWidth
        onClick={handleOpen}
        className={classNames(styles.button, {
          [styles.noPointerEvents]: visibleWorkspaces.length === 1,
        })}
      >
        {expanded ? t(`workspace.${workspace.permission}`) : <ComputerIcon />}
      </Button>
      <Menu
        anchorEl={anchor}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        {visibleWorkspaces.map((ws) => (
          <MenuItem
            key={ws.id}
            selected={ws.id === workspace.permission}
            onClick={handleWorkspaceChange}
            data-next-ws={ws.path}
          >
            {t(`workspace.${ws.permission}`)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default withRouter(WorkspaceSelect);
