import {
  SET_SIDE_MENU_CONFIG,
  SET_APP_MODE_AND_WORKSPACE,
  SET_SIDE_MENU_SCROLL,
} from '@tafs/constants/actions';

export const saveSideMenu = (groups) => (dispatch) => {
  return dispatch({
    type: SET_SIDE_MENU_CONFIG,
    payload: groups,
  });
};

export const saveScrollPosition = (pos) => (dispatch) => {
  return dispatch({
    type: SET_SIDE_MENU_SCROLL,
    payload: pos,
  });
};

export const setAppModeAndWorkspace = (data) => (dispatch) => {
  return dispatch({ type: SET_APP_MODE_AND_WORKSPACE, payload: data });
};
