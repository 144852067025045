import React, { PureComponent, Suspense } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';

import DefaultLayout from '@tafs/components/layouts/AppLayout';
import { LOGIN_PAGE } from '@tafs/constants/routes';
import LoadingProgress from '@tafs/components/common/LoadingProgress';

const Loading = () => <LoadingProgress />;

const LoginPage = Loadable({
  loader: () => import('@tafs/components/pages/Login'),
  loading: Loading,
});

const Page404 = Loadable({
  loader: () => import('@tafs/components/pages/Page404'),
  loading: Loading,
});

class App extends PureComponent {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  };

  render() {
    const { isAuthenticated, location } = this.props;
    const prevPathname = location.state?.from?.pathname || '/';
    return (
      <Suspense fallback={<LoadingProgress />}>
        {isAuthenticated ? (
          <Switch>
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route
              exact
              path="/login"
              render={() => (
                <Redirect
                  to={{
                    pathname: prevPathname,
                  }}
                />
              )}
            />
            <Route
              path="/"
              name="Home"
              component={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        ) : (
          <Switch>
            <Route path={LOGIN_PAGE} component={LoginPage} />

            {/* Redirect to Login, but remember requested route to return back after login success */}
            <Route
              render={({ location }) => (
                <Redirect
                  to={{
                    pathname: LOGIN_PAGE,
                    // this flag will be handled by login page as redirect path after success
                    state: { from: location },
                  }}
                />
              )}
            />
          </Switch>
        )}
      </Suspense>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  username: state.user.username,
});
/*
  withRouter is required because of App itself does not detect
  any prop or state changes of Router,
  so its child components will not be re-rendered
*/
export default withRouter(connect(mapStateToProps)(App));
