exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TaskStatus_no-data__26eQ_ {\n  padding: 8px;\n}\n\n.TaskStatus_a__6wTnN {\n  padding: 0;\n}\n\n.TaskStatus_controls__2nzjX {\n  display: flex;\n  justify-content: space-between;\n}\n\n.TaskStatus_controls__2nzjX .TaskStatus_auto-update__3fx5m {\n    padding-left: 32px;\n    color: gray;\n  }\n\n.TaskStatus_controls__2nzjX .TaskStatus_pagination__3lipE {\n    display: flex;\n    justify-content: flex-end;\n    padding: 0 16px;\n  }\n\n.TaskStatus_controls__2nzjX .TaskStatus_pagination__3lipE .TaskStatus_page-number__2XcG9 {\n      padding: 6px 8px;\n    }\n\n.TaskStatus_table__jwH9H {\n  width: 710px;\n}\n", ""]);

// Exports
exports.locals = {
	"no-data": "TaskStatus_no-data__26eQ_",
	"noData": "TaskStatus_no-data__26eQ_",
	"a": "TaskStatus_a__6wTnN",
	"controls": "TaskStatus_controls__2nzjX",
	"auto-update": "TaskStatus_auto-update__3fx5m",
	"autoUpdate": "TaskStatus_auto-update__3fx5m",
	"pagination": "TaskStatus_pagination__3lipE",
	"page-number": "TaskStatus_page-number__2XcG9",
	"pageNumber": "TaskStatus_page-number__2XcG9",
	"table": "TaskStatus_table__jwH9H"
};