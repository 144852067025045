import React, { useCallback, useMemo, useRef, memo } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { isNaN, isObject } from 'lodash';

import { NS } from '@tafs/i18n/i18nextConfig';
import NoData from '@tafs/components/common/Status/NoData';
import ServerSideDatasource from './ServerSideDatasource';
import TruncatableCell, {
  MAX_CELL_WIDTH,
} from '@tafs/components/common/GQTable/TruncatableCell';
import styles from './index.module.css';

const PAG_SIZE = 10;

const PageableTable = ({
  request,
  columnDefs,
  pagSize: customPagSize,
  containerClassName,
  className,
  gridRef,
  onGridReady,
  rowSelection,
  getContextMenuItems,
}) => {
  const agRef = useRef(null);
  const { t } = useTranslation([NS.CONSTANTS, NS.AG_GRID]);
  const gridCb = useCallback(
    (node) => {
      agRef.current = node;
      if (gridRef) gridRef.current = node;
    },
    [gridRef]
  );
  const pagSize = customPagSize || PAG_SIZE;

  const handleColumnDefs = (columnDefs) =>
    columnDefs
      .filter((col) => !col.ignore)
      .map((col) => ({
        ...col,
        headerName: t(col.headerName),
        ...(col?.truncatable && {
          cellRendererFramework: TruncatableCell,
          maxWidth: MAX_CELL_WIDTH,
        }),
      }));

  const onAgGridReady = useCallback(
    (params) => {
      params.api.setServerSideDatasource(
        ServerSideDatasource(request, pagSize)
      );

      if (onGridReady)
        onGridReady({
          reloadData: () => agRef.current.api.purgeServerSideCache(),
        });
    },
    [pagSize, request, onGridReady]
  );

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      sortable: true,
      resizable: true,
    };
  }, []);

  const gridOptions = useMemo(
    () => ({
      localeTextFunc: (key) => t(key, { ns: NS.AG_GRID }),
      enableRangeSelection: true,
      suppressMultiRangeSelection: true,
      suppressRowClickSelection: true,
    }),
    [t]
  );

  const getContextMenu = useCallback(
    (params) => {
      const copyCell = {
        icon: '<span class="ag-icon ag-icon-copy" unselectable="on"></span>',
        name: t('copyCell', { ns: NS.AG_GRID }),
        action: () => {
          try {
            const { api: gridApi } = agRef.current;
            const { rowIndex, column } = gridApi.getFocusedCell();
            const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);

            let value = gridApi.getValue(column.colId, rowNode);

            if (isNaN(value) || value === undefined) {
              value = rowIndex;
            } else if (column.colDef.valueFormatter) {
              value = column.colDef.valueFormatter({ value: value });
            } else if (column.colDef.cellRendererFramework) {
              const renderValue = column.colDef.cellRendererFramework({
                value: value,
                data: 'mock',
              });
              if (!isObject(renderValue) & renderValue) {
                value = renderValue;
              }
            }

            navigator.clipboard.writeText(value);
          } catch (error) {
            console.error('Cell content copy error');
          }
        },
      };

      let res = [
        ...(getContextMenuItems
          ? [...getContextMenuItems(params), 'separator']
          : []),
        'copy',
        'copyWithHeaders',
        'export',
      ];

      let placeIndex = res.findIndex((item) => item === 'copy');
      if (placeIndex === -1) res.unshift(copyCell);
      else res.splice(placeIndex, 0, copyCell);

      return res;
    },
    [t, getContextMenuItems]
  );

  const getMainMenuItems = ({ api, column, defaultItems }) => {
    const menuItem = {
      name: t('Copy column', { ns: NS.AG_GRID }),
      action: function () {
        api.clearRangeSelection();
        api.addCellRange({
          rowStartIndex: api.paginationProxy.topRowBounds.rowIndex,
          rowEndIndex:
            api.paginationProxy.topRowBounds.rowIndex +
            api.paginationProxy.pageSize -
            1,
          columnStart: column.colId,
          columnEnd: column.colId,
        });
        api.copySelectedRangeToClipboard();
        api.clearRangeSelection();
      },
    };
    defaultItems.splice(4, 0, menuItem);
    return defaultItems;
  };

  return (
    <div className={containerClassName}>
      <div className={classNames('ag-theme-balham', className, styles.root)}>
        <AgGridReact
          overlayNoRowsTemplate={NoData.stringify()}
          columnDefs={handleColumnDefs(columnDefs)}
          defaultColDef={defaultColDef}
          rowModelType={'serverSide'}
          getContextMenuItems={getContextMenu}
          rowSelection={rowSelection || 'multiple'}
          serverSideInfiniteScroll={true}
          pagination={true}
          paginationPageSize={pagSize}
          cacheBlockSize={pagSize}
          ref={gridCb}
          onGridReady={onAgGridReady}
          gridOptions={gridOptions}
          getMainMenuItems={getMainMenuItems}
        />
      </div>
    </div>
  );
};

export default memo(PageableTable);
