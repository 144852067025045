import ruNavigation from './ru/navigation.json';
import enNavigation from './en/navigation.json';
import zhNavigation from './zh/navigation.json';
import ruConstants from './ru/constants.json';
import enConstants from './en/constants.json';
import zhConstants from './zh/constants.json';
import ruRules from './ru/rules.json';
import enRules from './en/rules.json';
import zhRules from './zh/rules.json';
import ruAgGrid from './ru/agGrid.json';
import enAgGrid from './en/agGrid.json';
import zhAgGrid from './zh/agGrid.json';
import ruValidation from './ru/validation.json';
import enValidation from './en/validation.json';
import zhValidation from './zh/validation.json';
import ruCharts from './ru/charts.json';
import enCharts from './en/charts.json';
import zhCharts from './zh/charts.json';
import ruEntities from './ru/entities.json';
import enEntities from './en/entities.json';

import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';

const { PROD_MODE } = process.env;

export const NS = {
  NAVIGATION: 'navigation',
  CONSTANTS: 'constants',
  RULES: 'rules',
  AG_GRID: 'agGrid',
  VALIDATION: 'validation',
  CHARTS: 'charts',
  ENTITIES: 'entities',
};

export default {
  detection: { order: ['localStorage'] },
  supportedLngs: ['ru', ...(!PROD_MODE ? ['en', 'zh'] : [])],
  ns: Object.values(NS),
  defaultNS: 'constants',
  fallbackLng: 'ru',
  // saveMissing: true,
  // console logging ON
  debug: !PROD_MODE,
  partialBundledLanguages: true,

  resources: {
    ru: {
      navigation: ruNavigation,
      constants: ruConstants,
      rules: ruRules,
      agGrid: ruAgGrid,
      validation: ruValidation,
      charts: ruCharts,
      entities: ruEntities,
    },
    en: {
      navigation: enNavigation,
      constants: enConstants,
      rules: enRules,
      agGrid: enAgGrid,
      validation: enValidation,
      charts: enCharts,
      entities: enEntities,
    },
    zh: {
      navigation: zhNavigation,
      constants: zhConstants,
      rules: zhRules,
      agGrid: zhAgGrid,
      validation: zhValidation,
      charts: zhCharts,
    },
  },

  interpolation: {
    escapeValue: false,
  },

  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },

  // TODO: backend translation config
  // backend: {
  //   backendOptions: [{ expirationTime: 60 * 60 * 1000 }, {}], // 1 hour
  //   // loadPath: 'http://46.4.202.170:3051/locales/{{lng}}/{{ns}}.json',
  //   loadPath: 'http://localhost:3090/locales/resources?lng={{lng}}&ns={{ns}}',
  // },
};

export const localeMap = {
  ru: ruLocale,
  en: enLocale,
};
