import React from 'react';
import TableCell from '@material-ui/core/TableCell';

import styles from './index.module.css';
import StatusDetails from '@tafs/components/layouts/AppLayout/TopMenu/TaskStatus/StatusDetails';
import { getStatusDetailsData } from '@tafs/components/layouts/AppLayout/TopMenu/TaskStatus/StatusDetails/utils';

export function TaskHistoryDetails({ value }) {
  const record = JSON.parse(value);
  const details = getStatusDetailsData(record);

  return (
    <TableCell className={styles.statusDetails}>
      {details && <StatusDetails details={details} />}
    </TableCell>
  );
}
