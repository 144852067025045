import { SCHEDULED } from '@tafs/constants/tasks/status';

const dependantKeys = ['workspace', 'isSignal'];

export const getStatusDetailsData = (record) => {
  const details = { errorMessages: [] };

  if (record.params?.algo && record.params?.algo.length > 0)
    details.algorithms = record.params?.algo;

  if (record?.workSpace) details.workspace = record.workSpace;
  if (record?.jobError) details.errorMessages.push(record.jobError);

  if (record?.jobStatus === SCHEDULED) {
    if (record?.nextRunAt) details.scheduled = record.nextRunAt;
    if (record?.finishedAt) details.finished = record.finishedAt;
  }

  if (!!record?.metaData) {
    const { metaData } = record;

    if (!!metaData?.algorithms) details.algorithms = metaData.algorithms;
    if (!!metaData?.status) details.status = metaData.status;
    if (!!metaData?.message) details.message = metaData.message;
    if (!!metaData?.errors)
      metaData.errors.forEach((errorMessage) =>
        details.errorMessages.push(errorMessage)
      );
    if (!!metaData?.invalidTickersCurrencyContract)
      details.invalidTickersCurrencyContract =
        metaData.invalidTickersCurrencyContract;
    if (!!metaData?.count_load_strings)
      details.uploadDataRowCount = metaData.count_load_strings;
    if (!!metaData?.count_cancelled_load_string)
      details.uploadDataFailedRowCount = metaData.count_cancelled_load_string;
    if (!!metaData?.caseId) details.caseId = metaData.caseId;
  }

  if (details.errorMessages.length === 0) delete details.errorMessages;
  if (details?.algorithms && details.algorithms.length === 0)
    delete details.algorithms;

  if (
    /signal/.test(record.jobName) ||
    record.jobName === 'Create regulator case task'
  ) {
    details.isSignal = true;
  }

  return (
    !!Object.keys(details).filter((key) => !dependantKeys.includes(key))
      .length && details
  );
};
