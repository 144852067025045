import {
  assetClass,
  dealtype,
  businessLine,
  business,
  location,
  counterparty,
  dealmode,
  client,
  desk,
  entity,
  contractcode,
  portfolio,
  salesTeam,
  backOffice,
  middleOffice,
  trader,
  userId,
  venue,
} from './fields';

/* Report data slices */
/*****************************************************************/

export const assetClassSlice = { ...assetClass, by: [assetClass, dealtype] };
export const businessLineSlice = {
  ...businessLine,
  by: [businessLine, assetClass],
};
export const businessSlice = { ...business, by: [business, assetClass] };
export const clientSlice = { ...client, by: [client, business] };
export const counterpartySlice = {
  ...counterparty,
  by: [counterparty, business],
};
export const dealmodeSlice = { ...dealmode, by: [dealmode, businessLine] };
export const dealtypeSlice = { ...dealtype, by: [dealtype, assetClass] };
export const deskSlice = { ...desk, by: [desk, assetClass] };
export const entitySlice = { ...entity, by: [entity, business] };
export const contractcodeSlice = {
  ...contractcode,
  by: [contractcode, assetClass],
};
export const locationSlice = { ...location, by: [location, business] };
export const portfolioSlice = { ...portfolio, by: [portfolio, business] };
export const salesTeamSlice = { ...salesTeam, by: [salesTeam, location] };
export const backOfficeSlice = { ...backOffice, by: [backOffice, location] };
export const middleOfficeSlice = {
  ...middleOffice,
  by: [middleOffice, location],
};
export const traderSlice = { ...trader, by: [trader, location] };
export const userSlice = { ...userId, by: [userId, location] };
export const venueSlice = { ...venue, by: [venue, business] };

export const slices = [
  assetClassSlice,
  businessLineSlice,
  businessSlice,
  clientSlice,
  counterpartySlice,
  dealmodeSlice,
  dealtypeSlice,
  deskSlice,
  entitySlice,
  // contractcodeSlice, // temporary disable until fixed on backend
  locationSlice,
  portfolioSlice,
  salesTeamSlice,
  backOfficeSlice,
  middleOfficeSlice,
  traderSlice,
  userSlice,
  // venueSlice, // temporary disable until fixed on backend
];

export const slicesObj = slices.reduce((obj, slice) => {
  obj[slice.id] = slice;
  return obj;
}, {});
