exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TaskRow_stop-tooltip__1UP3x {\n  left: 0;\n  top: 0;\n  width: 15px;\n  height: 15px;\n  padding: 0;\n}\n\n.TaskRow_span__1ubIx {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: auto;\n}\n\n.TaskRow_close-icon__3TEch {\n  width: 11px;\n  height: 11px;\n}\n\n.TaskRow_stop-button__2ss5g {\n  position: absolute;\n  padding: 10px;\n  z-index: 1;\n}\n\n.TaskRow_status-button__3DXct {\n  color: #395a75 !important;\n  background-color: white !important;\n}\n\n.TaskRow_status-row__2ReNO > * {\n    height: 31px;\n  }\n\n.TaskRow_status-row__2ReNO:hover {\n    cursor: default;\n    background-color: #eee;\n  }\n\n.TaskRow_status-row__2ReNO .TaskRow_status-time__3F5jF {\n    padding-right: 8px;\n    color: gray;\n  }\n\n.TaskRow_status-row__2ReNO .TaskRow_status-icon__89DRm {\n    padding-right: 0px;\n  }\n\n.TaskRow_status-row__2ReNO .TaskRow_status-prompt__ZkqBm {\n    padding-left: 4px;\n    padding-right: 4px;\n  }\n\n.TaskRow_status-row__2ReNO .TaskRow_status-details__Msve6 {\n    padding-block: 0px;\n    padding-left: 0px;\n    padding-right: 5px;\n    color: gray;\n  }\n\n.TaskRow_unread-row__1tEht {\n  background-color: #e6f2ff;\n}\n\n.TaskRow_success__Cz6QZ {\n  fill: #33a952;\n}\n\n.TaskRow_fail__1yCS0 {\n  fill: #ea4435;\n}\n\n.TaskRow_expand__35jR1 {\n  padding: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"stop-tooltip": "TaskRow_stop-tooltip__1UP3x",
	"stopTooltip": "TaskRow_stop-tooltip__1UP3x",
	"span": "TaskRow_span__1ubIx",
	"close-icon": "TaskRow_close-icon__3TEch",
	"closeIcon": "TaskRow_close-icon__3TEch",
	"stop-button": "TaskRow_stop-button__2ss5g",
	"stopButton": "TaskRow_stop-button__2ss5g",
	"status-button": "TaskRow_status-button__3DXct",
	"statusButton": "TaskRow_status-button__3DXct",
	"status-row": "TaskRow_status-row__2ReNO",
	"statusRow": "TaskRow_status-row__2ReNO",
	"status-time": "TaskRow_status-time__3F5jF",
	"statusTime": "TaskRow_status-time__3F5jF",
	"status-icon": "TaskRow_status-icon__89DRm",
	"statusIcon": "TaskRow_status-icon__89DRm",
	"status-prompt": "TaskRow_status-prompt__ZkqBm",
	"statusPrompt": "TaskRow_status-prompt__ZkqBm",
	"status-details": "TaskRow_status-details__Msve6",
	"statusDetails": "TaskRow_status-details__Msve6",
	"unread-row": "TaskRow_unread-row__1tEht",
	"unreadRow": "TaskRow_unread-row__1tEht",
	"success": "TaskRow_success__Cz6QZ",
	"fail": "TaskRow_fail__1yCS0",
	"expand": "TaskRow_expand__35jR1"
};