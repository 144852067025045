import { toast } from 'react-toastify';
import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const STATUS_CODE_UNAUTHORIZED = '401';

class NotifyService {
  error(message) {
    if (message.indexOf(STATUS_CODE_UNAUTHORIZED) === -1) {
      toast.error(message, {
        icon: () => (
          <ErrorOutlineIcon style={{ color: 'var(--toastify-color-error)' }} />
        ),
        style: { borderLeft: 'var(--toastify-color-error) solid 5px' },
      });
    }
  }

  info(message) {
    toast(message);
  }

  warn(message) {
    toast.warn(message, {
      icon: () => (
        <WarningOutlinedIcon
          style={{ color: 'var(--toastify-color-warning)' }}
        />
      ),
      style: { borderLeft: 'var(--toastify-color-warning) solid 5px' },
    });
  }

  success(message) {
    toast.success(message, {
      icon: () => (
        <CheckCircleOutlineIcon
          style={{ color: 'var(--toastify-color-success)' }}
        />
      ),
      style: { borderLeft: 'var(--toastify-color-success) solid 5px' },
    });
  }
}

export const notifyService = new NotifyService();
