import { format } from 'date-fns';

import {
  SET_OVERLAP_DEALS_DATE,
  SET_OVERLAP_ORDERS_DATE,
  SET_OVERLAP_CLIENTS_DATE,
  SET_UNPROCESSED_INC_DATE,
} from '../../constants/actions';

const dateFrom = format(new Date(), 'yyyy-MM-dd');
const dateTo = format(new Date(), 'yyyy-MM-dd');

const initialState = {
  DEAL: { dateFrom, dateTo },
  ORDER: { dateFrom, dateTo },
  CLIENT: { dateFrom, dateTo },
  INCIDENTS_DRP: { dateFrom, dateTo },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_OVERLAP_DEALS_DATE:
      return {
        ...state,
        DEAL: {
          ...state.overlapDeals,
          dateFrom: action.payload.dateFrom || state.DEAL.dateFrom,
          dateTo: action.payload.dateTo || state.DEAL.dateTo,
        },
      };
    case SET_OVERLAP_ORDERS_DATE:
      return {
        ...state,
        ORDER: {
          ...state.overlapDeals,
          dateFrom: action.payload.dateFrom || state.ORDER.dateFrom,
          dateTo: action.payload.dateTo || state.ORDER.dateTo,
        },
      };
    case SET_OVERLAP_CLIENTS_DATE:
      return {
        ...state,
        CLIENT: {
          ...state.overlapDeals,
          dateFrom: action.payload.dateFrom || state.CLIENT.dateFrom,
          dateTo: action.payload.dateTo || state.CLIENT.dateTo,
        },
      };
    case SET_UNPROCESSED_INC_DATE:
      return {
        ...state,
        INCIDENTS_DRP: {
          ...state.overlapDeals,
          dateFrom: action.payload.dateFrom || state.INCIDENTS_DRP.dateFrom,
          dateTo: action.payload.dateTo || state.INCIDENTS_DRP.dateTo,
        },
      };
    default:
      return state;
  }
}
